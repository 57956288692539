import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "fields-container" }
const _hoisted_2 = {
  key: 0,
  class: "static-fields"
}
const _hoisted_3 = { class: "calendars" }
const _hoisted_4 = { class: "calendar-input" }
const _hoisted_5 = { class: "input-container" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = { class: "calendar" }
const _hoisted_8 = { class: "date-format" }
const _hoisted_9 = { class: "calendar-input" }
const _hoisted_10 = { class: "input-container" }
const _hoisted_11 = { class: "input" }
const _hoisted_12 = { class: "calendar" }
const _hoisted_13 = { class: "date-format" }
const _hoisted_14 = { class: "power-fields" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "calendars" }
const _hoisted_17 = { class: "mb-1 mt-2 d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      id: "advanced-search",
      visible: _ctx.showDialog,
      "onUpdate:visible": [
        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
        _ctx.closed
      ],
      draggable: false,
      modal: true,
      class: "p-fluid fw-bold",
      header: _ctx.$t('advancedSearch.title')
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('global.button.cancel'),
          icon: "pi pi-times",
          class: "secondary-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closed()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('global.button.apply'),
          icon: "pi pi-check",
          class: "primary-button",
          onClick: _ctx.applyQuery
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.searchDateFields)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchDateFields, (field, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "row",
                    key: index
                  }, [
                    _createElementVNode("label", null, _toDisplayString(field.label), 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", {
                        class: "calendar-input-format-hint",
                        style: _normalizeStyle(_ctx.fieldStyle)
                      }, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                              _createVNode(_component_InputText, {
                                id: "inputText",
                                class: "text-input",
                                modelValue: field.from,
                                "onUpdate:modelValue": ($event: any) => ((field.from) = $event),
                                placeholder: _ctx.$t('advancedSearch.from')
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_Calendar, {
                              style: {"width":"100%"},
                              selectionMode: 'single',
                              view: field.view.dateView,
                              showTime: field.view.showTime,
                              hourFormat: "24",
                              dateFormat: "yy/mm/dd",
                              showIcon: true,
                              manualInput: false,
                              tabindex: "-1",
                              "onUpdate:modelValue": ($event: any) => (_ctx.updateDateFrom(field.id, $event))
                            }, null, 8, ["view", "showTime", "onUpdate:modelValue"])
                          ])
                        ]),
                        _createElementVNode("label", _hoisted_8, _toDisplayString(field.view.dateView === 'year' ? _ctx.$t('global.dateFormats.yearOnly') : _ctx.$t('global.dateFormats.date')), 1)
                      ], 4),
                      _createElementVNode("div", {
                        class: "calendar-input-format-hint",
                        style: _normalizeStyle(_ctx.fieldStyle)
                      }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createVNode(_component_InputText, {
                                class: "text-input",
                                modelValue: field.to,
                                "onUpdate:modelValue": ($event: any) => ((field.to) = $event),
                                placeholder: _ctx.$t('advancedSearch.to')
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_Calendar, {
                              style: {"width":"100%"},
                              selectionMode: 'single',
                              view: field.view.dateView,
                              showTime: field.view.showTime,
                              hourFormat: "24",
                              dateFormat: "yy/mm/dd",
                              showIcon: true,
                              manualInput: false,
                              tabindex: "-1",
                              "onUpdate:modelValue": ($event: any) => (_ctx.updateDateTo(field.id, $event))
                            }, null, 8, ["view", "showTime", "onUpdate:modelValue"])
                          ])
                        ]),
                        _createElementVNode("label", _hoisted_13, _toDisplayString(field.view.dateView === 'year' ? _ctx.$t('global.dateFormats.yearOnly') : _ctx.$t('global.dateFormats.date')), 1)
                      ], 4),
                      _withDirectives(_createVNode(_component_Button, {
                        style: _normalizeStyle(_ctx.fieldBtnStyle),
                        class: "secondary-button delete-button",
                        icon: "pi pi-calendar-times",
                        "icon-pos": "left",
                        disabled: !field.from && !field.to,
                        onClick: ($event: any) => (_ctx.clearCalendars(field.id))
                      }, null, 8, ["style", "disabled", "onClick"]), [
                        [
                          _directive_tooltip,
                          _ctx.$t('advancedSearch.clear'),
                          void 0,
                          { bottom: true }
                        ]
                      ])
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('advancedSearch.field')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queryModels, (query, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", {
                      style: _normalizeStyle(_ctx.fieldStyle)
                    }, [
                      _createVNode(_component_Dropdown, {
                        modelValue: query.command,
                        "onUpdate:modelValue": [($event: any) => ((query.command) = $event), ($event: any) => (_ctx.updateField(query.command, index))],
                        options: _ctx.hintList,
                        filter: "",
                        "option-label": "text",
                        "option-value": "value",
                        class: "drop-down w-100 text-left",
                        "show-clear": true
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                    ], 4),
                    _createElementVNode("div", {
                      style: _normalizeStyle(_ctx.fieldStyle)
                    }, [
                      _createVNode(_component_InputText, {
                        modelValue: query.value,
                        "onUpdate:modelValue": ($event: any) => ((query.value) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ], 4),
                    _withDirectives(_createVNode(_component_Button, {
                      style: _normalizeStyle(_ctx.fieldBtnStyle),
                      class: "secondary-button delete-button",
                      icon: "pi pi-trash",
                      "icon-pos": "left",
                      onClick: ($event: any) => (_ctx.removeQuery(query))
                    }, null, 8, ["style", "onClick"]), [
                      [
                        _directive_tooltip,
                        _ctx.$t('advancedSearch.remove'),
                        void 0,
                        { bottom: true }
                      ]
                    ])
                  ])
                ])
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_17, [
              _withDirectives(_createVNode(_component_Button, {
                style: _normalizeStyle(_ctx.fieldBtnStyle),
                class: "primary-button",
                icon: "pi pi-plus",
                "icon-pos": "left",
                onClick: _ctx.addNewQuery
              }, null, 8, ["style", "onClick"]), [
                [
                  _directive_tooltip,
                  _ctx.$t('advancedSearch.addQuery'),
                  void 0,
                  { bottom: true }
                ]
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "header", "onUpdate:visible"])
  ]))
}