import { Component } from "vue-facing-decorator";
import { toastManager } from "./managers/toast.manager";
import BaseControl from "./components/complex.controls/base.control";
import { infrastructureStatusManager } from "./managers/infrastructure.manager";
import { authModule } from "./store/auth.module";
import NavbarComponent from "./components/complex.controls/mobile/navbar/navbar.vue";

@Component({ components: { NavbarComponent } })
export default class AppComponent extends BaseControl {
    hideFeedbackForm = false;
    wasUnderMaintenance = false;

    showFeedbackForm() {
        this.setSubComponent("FeedbackForm", {});
    }

    showMessage() {
        toastManager.success(this, this.$t("feedback.sent"), this.$t("toast.thanks"));
    }

    async mounted() {
        this.$router.beforeEach(route => {
            //Something about this closure that prevents this from being simplified, so don't change
            if (route.path.includes("login")
              || route.path.includes("printpage")
              || route.path.includes("underMaintenance")
              || route.path.includes("qr-add-attachments")) this.hideFeedbackForm = true;
            else this.hideFeedbackForm = false;
        });

        setInterval(async () => {
            const isAlive = await infrastructureStatusManager.isApiAliveAsync();

            if (isAlive) {
                if (this.wasUnderMaintenance) await this.redirectToLoginPageAndReset();
                return;
            }

            this.wasUnderMaintenance = true;
            await this.$router.push("/underMaintenance");
        }, 15000);
    }

    async redirectToLoginPageAndReset(): Promise<void> {
        await authModule.logout();
        this.wasUnderMaintenance = false;
        await this.$router.push("/login?returnUrl=/");
    }
}
