import { AxiosInstance, AxiosResponse } from "axios";
import httpclient from "../utilities/helpers/http.client.axios";
import ResponseSoftwareVersionModel from "../models/response.models/response.software.version.model";
import { RequestSoftwareVersionModel } from "@/models/request.models/request.software.version.model";
import { plainToInstance } from "class-transformer";
import { VesselFullModel } from "@/models/models/vessel.full.model";
import BaseSoftwareModel from "@/models/models/software/base.software.model";
import BaseModel from "@/models/models/base/base.model";

export class SoftwareVersionManager {
    private readonly api: AxiosInstance = httpclient;

    async getSoftwareVersionsByVesselId(vesselId: number, code: string | null = null): Promise<ResponseSoftwareVersionModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseSoftwareVersionModel[]> = await this.api.get(`/SoftwareVersion/GetSoftwareVersionsByVesselId/${vesselId}?code=${code}`);

            response.data.forEach(p => {p.vessel = plainToInstance(VesselFullModel, p.vessel);});
            return plainToInstance(ResponseSoftwareVersionModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async createSoftwareVersionAsync(requestSoftwareVersion: RequestSoftwareVersionModel) {
        try {
            const response: AxiosResponse<RequestSoftwareVersionModel> = await this.api.post("/SoftwareVersion/Create", requestSoftwareVersion);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateSoftwareVersionAsync(requestSoftwareVersion: RequestSoftwareVersionModel) {
        try {
            const response: AxiosResponse<RequestSoftwareVersionModel> = await this.api.put("/SoftwareVersion/Update", requestSoftwareVersion);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async deleteSoftwareVersion(id: number) {
        try {
            const response = await this.api.delete(`/SoftwareVersion/DeleteSoftwareVersion/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    //Hardware
    async getHardwareBuildsAsync(): Promise<BaseSoftwareModel[] | Error> {
        try {
            const response: AxiosResponse<BaseSoftwareModel[]> = await this.api.get("/SoftwareVersion/HardwareBuilds");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async createHardwareBuildAsync(request: BaseSoftwareModel): Promise<BaseSoftwareModel | Error> {
        try {
            const response: AxiosResponse<BaseSoftwareModel> = await this.api.post("/SoftwareVersion/CreateHardwareBuild", request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateHardwareBuildAsync(request: BaseSoftwareModel): Promise<BaseSoftwareModel | Error> {
        try {
            const response: AxiosResponse<BaseSoftwareModel> = await this.api.put("/SoftwareVersion/UpdateHardwareBuild", request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async deleteHardwareBuildAsync(id: number): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.delete(`/SoftwareVersion/DeleteHardwareBuild/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    //Software
    async getSoftwareBuildsAsync(): Promise<BaseSoftwareModel[] | Error> {
        try {
            const response: AxiosResponse<BaseSoftwareModel[]> = await this.api.get("/SoftwareVersion/SoftwareBuilds");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async createSoftwareBuildAsync(request: BaseSoftwareModel): Promise<BaseSoftwareModel | Error> {
        try {
            const response: AxiosResponse<BaseSoftwareModel> = await this.api.post("/SoftwareVersion/CreateSoftwareBuild", request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateSoftwareBuildAsync(request: BaseSoftwareModel): Promise<BaseSoftwareModel | Error> {
        try {
            const response: AxiosResponse<BaseSoftwareModel> = await this.api.put("/SoftwareVersion/UpdateSoftwareBuild", request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async deleteSoftwareBuildAsync(id: number): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.delete(`/SoftwareVersion/DeleteSoftwareBuild/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getControlSystemTypesAsync(): Promise<BaseModel[] | Error> {
        try {
            const response: AxiosResponse<BaseModel[]> = await this.api.get("/SoftwareVersion/ControlSystemTypes");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getTypesAsync(): Promise<BaseModel[] | Error> {
        try {
            const response: AxiosResponse<BaseModel[]> = await this.api.get("/SoftwareVersion/Types");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}

export const softwareVersionManager = new SoftwareVersionManager();
