import { UserModel } from "../models/user.model";
import { ResponseBaseModel } from "./response.base.model";
import { VesselFullModel } from "../models/vessel.full.model";
import { Type } from "class-transformer";
import BaseSoftwareModel from "@/models/models/software/base.software.model";

export default class ResponseSoftwareVersionModel extends ResponseBaseModel {
    vesselId!: number;

    vessel!: VesselFullModel;

    @Type(() => Date)
    date!: Date;

    createdByUser!: UserModel;

    controlSystemType!: string;

    portHardware!: BaseSoftwareModel;
    portSoftware!: BaseSoftwareModel;

    starboardHardware!: BaseSoftwareModel;
    starboardSoftware!: BaseSoftwareModel;

    displayHardware!: BaseSoftwareModel;
    displaySoftware!: BaseSoftwareModel;

    comment?: string;

    isControlSystemTypeNew: boolean = false;

    isPortHardwareNew: boolean = false;
    isPortSoftwareNew: boolean = false;

    isStarboardHardwareNew: boolean = false;
    isStarboardSoftwareNew: boolean = false;

    isDisplayHardwareNew: boolean = false;
    isDisplaySoftwareNew: boolean = false;
}
