import { IDropDownOptions } from "../components/base.controls/colored.dropdown/colored.dropdown";
import { GlobalTableModel } from "../models/models/global.table.model";

export const global = {
    getOptionsFromGlobal(items: GlobalTableModel[]): IDropDownOptions[] {
        if (!items) {
            return [];
        }

        return items.map(gt => {
            return {
                text: gt.value,
                value: gt.id
            };
        });
    }
};

declare global {
    interface Array<T> {
        moveToTop(this: T[], toMove: T[], accessor: (item: T) => any): T[];
    }

    interface String {
        serializedToLocalDateString(this: string): string;
        serializedToLocalString(this: string): string;
    }
}

Array.prototype.moveToTop = function <T>(this: T[], toMove: T[], accessor: (item: T) => any): T[] {
    const toMoveSet = new Set(toMove.map(accessor));

    return this.sort((a, b) => {
        const aInToMove = toMoveSet.has(accessor(a));
        const bInToMove = toMoveSet.has(accessor(b));

        if (aInToMove && bInToMove) return toMove.findIndex(item => accessor(item) === accessor(a)) - toMove.findIndex(item => accessor(item) === accessor(b));

        if (aInToMove) return -1;

        if (bInToMove) return 1;

        return 0;
    });
};

String.prototype.serializedToLocalDateString = function (this: Date | string): string {
    return this instanceof Date ? this.toLocaleDateString() : new Date(this).toLocaleDateString();
};

String.prototype.serializedToLocalString = function (this: Date | string): string {
    return this instanceof Date ? this.toLocaleString() : new Date(this).toLocaleString();
};