<template>
    <div>
        <div class="row" v-if="!forgotPassword">
            <div class="col-12 mb-4">
                <h3>{{ $t("login.label.signIn") }}</h3>
            </div>
        </div>
        <div class="row" v-if="!forgotPassword">
            <div class="col-12 mb-3">
                <label for="email" :class="{ 'p-error': v$.email.$invalid }" class="form-label">{{ $t("login.label.email") }}</label>
                <InputText
                    id="email"
                    v-model="v$.email.$model"
                    type="email"
                    :class="{ 'p-invalid': v$.email.$invalid }"
                    class="form-control form-field"
                    placeholder="name@marinejetpower.com" />
              <small v-if="v$.email.$error" class="p-error">
                {{ v$.email.$errors[0].$message }}
              </small>
            </div>
        </div>
        <div class="row" v-if="!forgotPassword">
            <div class="col-12 mb-3 d-flex flex-column">
                <label for="password" :class="{ 'p-error': v$.password.$invalid }" class="form-label">{{ $t("login.label.password") }}</label>
                <Password
                    id="password"
                    v-model="v$.password.$model"
                    :class="{ 'p-invalid': v$.password.$invalid }"
                    :feedback="false"
                    placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                    input-class="form-control"
                    toggle-mask />
              <small v-if="v$.password.$error" class="p-error">
                {{ v$.password.$errors[0].$message }}
              </small>
            </div>
        </div>
        <div v-if="!forgotPassword" style="display: flex; justify-content: space-between; align-items: center">
            <div>
                <Checkbox id="remember-information" v-model="rememberInformation" type="checkbox" :binary="true" />
                <label class="mx-2" for="remember-information">{{ $t("login.label.staySignedIn") }}</label>
            </div>
            <div>
                <span class="forgot-password" @click="forgotPassword = true">Forgot password?</span>
            </div>
        </div>
        <div v-if="!forgotPassword" style="display: flex; justify-content: flex-end; margin-top: 2rem">
            <Button :disabled="isLoggingIn" type="button" class="justify-content-center primary" style="width: 15rem" @click="submit">
                {{ $t("login.button.login") }}
            </Button>
        </div>

        <!--   Forgot password   -->
        <div v-if="forgotPassword" style="margin-top: 8rem">
            <h3>{{ $t("user.forgotPassword") }}</h3>
            <div style="display: flex; flex-direction: column; margin-top: 2rem">
                <label>{{ $t("user.email") }}</label>
                <InputText v-model="v$.emailToSendPasswordReset.$model" :class="{ 'p-invalid': v$.emailToSendPasswordReset.$invalid }" />
              <small v-if="v$.emailToSendPasswordReset.$error" class="p-error">
                {{ v$.emailToSendPasswordReset.$errors[0].$message }}
              </small>
            </div>

            <div style="display: flex; justify-content: flex-end; margin-top: 2rem">
                <Button class="secondary-button" :label="$t('user.back')" style="border-radius: 2rem; width: 10rem" @click="forgotPassword = false" />
                <Button
                    class="primary-button"
                    :label="$t('user.send')"
                    style="margin-left: 2rem; border-radius: 2rem; width: 10rem"
                    :disabled="emailToSendPasswordReset.length < 1 || v$.$errors.length > 0"
                    @click="sendPasswordResetAsync" />
            </div>
        </div>
    </div>
</template>

<script src="./login.form.control.ts"></script>
<style lang="scss" scoped src="./login.form.control.scoped.scss" />
