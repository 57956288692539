import { Component, Prop } from "vue-facing-decorator";
import DialogBaseModuleControl from "../../../dialog.base.control";
import { ColumnModel } from "@/models/table/column.model";
import _ from "lodash";

@Component({})
export default class ColumnsChangeDialogComponent extends DialogBaseModuleControl {
    @Prop()
    columns!: ColumnModel[];

    @Prop()
    storageKey!: string;

    @Prop()
    confirmCallback!: (columns: ColumnModel[] | null) => void;

    regularColumns: ColumnModel[] = [];
    frozenColumns: ColumnModel[] = [];
    actionColumns: ColumnModel[] = [];

    created(): void {
        this.columns.forEach(c => (c.hideColumn = !!c.hideColumn));
        const cloned = _.cloneDeep(this.columns);
        this.actionColumns = cloned.filter(col => col.frozen && col.alignFrozen == "right");
        this.regularColumns = cloned.filter(col => !col.frozen);
        this.frozenColumns = cloned.filter(col => col.frozen && col.alignFrozen == "left");
    }

    onRegularRowReorder(event: { value: ColumnModel[] }) {
        this.regularColumns = event.value;
    }

    onFrozenRowReorder(event: { value: ColumnModel[] }) {
        this.frozenColumns = event.value;
    }

    saveSettings(): void {
        const allColumns = [...this.frozenColumns, ...this.regularColumns, ...this.actionColumns];
        localStorage.setItem(this.storageKey, JSON.stringify(allColumns));
        if (this.confirmCallback) {
            this.confirmCallback(allColumns);
        }
        this.closed();
    }

    restore(): void {
        localStorage.setItem(this.storageKey, "");
        if (this.confirmCallback) {
            this.confirmCallback(null);
        }
        this.closed();
    }

    freezeColumn(column: ColumnModel, state: boolean): void {
        column.frozen = state;
        column.alignFrozen = state ? "left" : undefined;
        column.hideColumn = false;
        const allColumns = _.cloneDeep([...this.regularColumns, ...this.frozenColumns]);
        this.regularColumns = allColumns.filter(col => !col.frozen);
        this.frozenColumns = allColumns.filter(col => col.frozen);
    }
}
