import { Component, Prop } from "vue-facing-decorator";
import DialogBaseModuleControl from "../../dialog.base.control";

@Component({})
export default class ConfirmationDialogComponent extends DialogBaseModuleControl {
    @Prop()
    header!: string;

    @Prop()
    messages!: string[];

    @Prop({ default: "delete" })
    type!: "confirm" | "delete";

    @Prop({ default: true})
    canDelete!: boolean;

    @Prop()
    confirmCallback!: () => void;

    onConfirm(): void {
        this.confirmCallback();
        this.closed();
    }
}
