<template>
    <div v-if="options" :style="{ width: isMobileView ? '100%' : '200px' }">
        <Dropdown
            v-model="selection"
            :class="['drop-down w-100 text-left']"
            :options="options"
            scroll-height="300px"
            option-label="text"
            option-value="value"
            :disabled="disabled"
            @click="stopExpandEvent"
            @change="updateValue">
            <template #value="{ value }">
                <div v-if="coloredDropdownColors?.length">
                    <img alt=""
                         class="mx-1 mb-1"
                        :src="coloredDropdownColors.find(color => color.value === value)?.imageSource" />
                    <span>{{ options.find(opt => opt.value === value)?.text }}</span>
                </div>
                <div v-else>
                    {{ options.find(opt => opt.value === value)?.text }}
                </div>
            </template>
            <template #option="{ option }">
                <div v-if="coloredDropdownColors?.length" >
                    <img alt=""
                        class="mx-1 mb-1"
                        :src="coloredDropdownColors.find(color => color.value === option.value)?.imageSource" />
                    <span>{{ option.text }}</span>
                </div>
                <div v-else>
                    {{ option.text }}
                </div>
            </template>
        </Dropdown>
    </div>
</template>
<script src="./colored.dropdown.ts"></script>
