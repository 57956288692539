import axios, { AxiosInstance } from "axios";
import axiosJsonApiInterceptor from "../interceptors/axios.json.api.interceptor";
import axiosJwtTokenInterceptor from "../interceptors/axios.jwt.token.interceptor";

const api: AxiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_URL}`
});

api.interceptors.request.use(axiosJwtTokenInterceptor);

api.interceptors.request.use(axiosJsonApiInterceptor);

export default api;
