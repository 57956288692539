import { Component, Prop } from "vue-facing-decorator";
import DialogBaseModuleControl from "../../../dialog.base.control";
import { IDropDownOptions } from "../../colored.dropdown/colored.dropdown";
import Tooltip from "primevue/tooltip";
import AdvancedSearchDateFieldModel from "@/models/models/advanced.search.date.field.model";

@Component({
    directives: {
        Tooltip
    }
})
export default class AdvancedSearchDialogComponent extends DialogBaseModuleControl {
    @Prop()
    query: string[] = [];

    @Prop({ default: [] })
    hintList: IDropDownOptions[] = [];

    @Prop()
    confirmCallback!: (query: string[] | null) => void;

    @Prop()
    dateFields: AdvancedSearchDateFieldModel[] = [];

    searchDateFields: AdvancedSearchDateFieldModel[] = [];

    queryModels: { command: string | null; value: string | null }[] = [];

    fieldStyle = { width: "45%" };
    fieldBtnStyle = { width: "6%" };
    calendarIconStyle = { "font-size": "1.5rem" };

    declare $refs: {
        calendarRef: any;
    };

    created(): void {
        this.queryModels = this.query?.length
            ? this.query.map(q => {
                  if (this.dateFields && this.dateFields.find(df => q.includes(df.value))) return { command: null, value: null };

                  if (q.startsWith("/")) {
                      const parts = q.split(":");
                      return {
                          command: parts[0].substring(1),
                          value: parts[1]
                      };
                  } else {
                      return {
                          command: null,
                          value: q
                      };
                  }
              })
            : [{ command: null, value: null }];

        //If there are more of the same type of view, this has to change
        if (this.dateFields) {
            this.searchDateFields = this.dateFields.map(df => {
                const query = this.query.find(q => q.includes(df.value) && q.startsWith("/"));
                if (query && df.view.dateView === "year") {
                    const parts = query.split(":")[1];
                    const dates = parts.split("-");
                    return { ...df, from: dates[0], to: dates[1] };
                } else if(query && df.view.dateView === "date") {
                    const dates = query.split(":")[1].split("-");
                    return { ...df, from: dates[0], to: dates[1] };
                } else {
                    return { ...df };
                }
            });
        } else {
            this.searchDateFields = this.dateFields;
        }
    }

    applyQuery(): void {
        let queries = this.queryModels.filter(qm => qm.value).map(qm => (qm.command ? `/${qm.command}:${qm.value}` : qm.value ?? ""));

        if(this.searchDateFields.length) {
            const dateQueries = this.searchDateFields.filter(df => df.from && df.to).map(df => `/${df.value}:${df.from + "-" + df.to}`);
            if(queries.length) queries = queries.concat(dateQueries);
            else queries = dateQueries;
        }

        this.confirmCallback(queries);
        this.closed();
    }

    addNewQuery(): void {
        this.queryModels.push({ command: null, value: null });
    }

    removeQuery(query: { command: string | null; value: string | null }): void {
        this.queryModels = this.queryModels.filter(q => q != query);
    }

    updateDateFrom(id: number, date: Date): void {
        this.searchDateFields = this.searchDateFields.map(df =>
            df.id === id ? { ...df, from: df.view.dateView === "year" ? date.getFullYear().toString() : this.getFormattedDate(date) } : df
        );
    }

    updateDateTo(id: number, date: Date): void {
        this.searchDateFields = this.searchDateFields.map(df =>
            df.id === id ? { ...df, to: df.view.dateView === "year" ? date.getFullYear().toString() : this.getFormattedDate(date)  } : df
        );
    }

    clearCalendars(id: number): void {
        this.searchDateFields = this.searchDateFields.map(df => (df.id === id ? { ...df, from: undefined, to: undefined } : df));
    }

    updateField(value: string | null, index: number): void {
        if(!value)
            this.queryModels = this.queryModels.map((q, idx) => (index === idx ? { ...q, value: "" } : q));
    }

    private getFormattedDate(date: Date): string {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${date.getFullYear()}/${month}/${day}`;
    }
}
