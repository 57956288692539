import { Component } from "vue-facing-decorator";
import { FeedbackModel } from "../../../models/models/feedback.model";
import DialogBaseModuleControl from "../../dialog.base.control";
import { required } from "@vuelidate/validators";
import { IDropDownOptions } from "../../base.controls/colored.dropdown/colored.dropdown";
import { enumHelper } from "../../../utilities/helpers/enum.helper";
import { FeedbackType } from "../../../models/enums/feedback.type";
import AttachmentDownloadComponent from "../attachment.download/attachment.download.vue";
import { feedbackManager } from "../../../managers/feedback.manager";
import { FeedbackSeverity } from "@/models/enums/feedback.severity";

@Component({
    components: {
        AttachmentDownloadComponent
    },
    options: {
        validations() {
            return {
                feedback: {
                    comment: { required },
                    feedbackType: { required },
                    feedbackSeverity: { required }
                }
            };
        }
    }
})
export default class FeedbackFormComponent extends DialogBaseModuleControl {
    feedback: FeedbackModel = {
        comment: "",
        feedbackType: FeedbackType.GeneralFeedback,
        feedbackSeverity: FeedbackSeverity.Low
    };

    feedbackTypes: IDropDownOptions[] = [];
    feedbackSeverities: IDropDownOptions[] = [];
    created() {
        this.feedbackTypes = enumHelper.getValueFromEnum(FeedbackType, this);
        this.feedbackSeverities = enumHelper.getValueFromEnum(FeedbackSeverity, this);
    }

    async createFeedback() {
        this.v$.$validate();
        if (this.v$.$error) {
            return;
        }

        this.feedback.buildVersion = process.env.VUE_APP_VERSION;

        await feedbackManager.saveFeedback(this.feedback);
        this.saved();
    }

    elPhotoLoader(event: { files: File[] }): void {
        const files = event.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = async () => {
            const base64: string = reader.result as string;
            this.feedback.imageSource = base64.substr(base64.indexOf(",") + 1);
        };
    }

    async downloadImage(content: string) {
        this.setSubComponent("AttachmentDownloadComponent", {
            content: content,
            fileName: `Feedback.jpg`,
            contentType: "image/jpeg"
        });
    }
}
