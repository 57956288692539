import { AxiosInstance, AxiosResponse } from "axios";
import { ResponseTaskMultiMediaModel } from "@/models/response.models/response.task.multi.media.model";
import httpclient from "../utilities/helpers/http.client.axios";
import { plainToInstance } from "class-transformer";

export class TaskMultiMediaManager {
    private readonly api: AxiosInstance = httpclient;

    public async getMultiMediaByTaskIdAsync(taskResultId: number): Promise<ResponseTaskMultiMediaModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseTaskMultiMediaModel[]> = await this.api.get(
                `/ServiceTask/GetTaskMultiMediaByTaskId/${taskResultId}`
            );
            return plainToInstance(ResponseTaskMultiMediaModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async getMultiMediaContentAsync(attachmentId: number, code: string | null = null): Promise<string | Error> {
        try {
            const response = await this.api.get(`/ServiceTask/GetMultiMediaContent/${attachmentId}?code=${code}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getQrCodeParamsAsync(taskResultId: number): Promise<string | Error> {
        try {
            const response = await this.api.get(`/ServiceTask/GetQrCodeParams/${taskResultId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async createMultiMediaAsync(requestAttachment: FormData): Promise<any | Error> {
        try {
            const response = await this.api.post("/ServiceTask/CreateMultiMedia", requestAttachment);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async deleteMultiMediaAsync(id: number) {
        try {
            const response = await this.api.delete(`/ServiceTask/DeleteMultiMedia/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}
export const taskMultiMediaManager = new TaskMultiMediaManager();
