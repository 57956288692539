import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { ResponseAttachmentModel } from "@/models/response.models/response.attachment.model";
import { BaseModule } from "./base.module";
import store, { IStore } from "./index";

export interface IAttachmentState {
    attachments: ResponseAttachmentModel[];
}

@Module({
    store,
    name: "attachment",
    dynamic: true,
    namespaced: true
})
class AttachmentModule extends BaseModule implements IAttachmentState {
    private readonly store!: IStore;

    _attachments: ResponseAttachmentModel[] = [];

    get attachments(): ResponseAttachmentModel[] {
        return this._attachments;
    }

    @Mutation
    async setIsAttachmentLoading(value: boolean) {
        this.isLoading = value;
    }

    @Mutation
    public async setFetchAttachmentsSuccessful(responseAttachments: ResponseAttachmentModel[]) {
        this._attachments = responseAttachments;
        this.isLoading = false;
    }

    @Mutation
    public async setFetchAttachmentsUnsuccessful(error: Error) {
        this.isLoading = false;
        this.errors.push(error);
    }

    @Action
    async fetchAttachments(vesselId: number) {
        this.context.commit("setIsAttachmentLoading", true);
        const response = await this.store.attachmentManager.getAttachmentsByVesselId(vesselId);

        if (response instanceof Error) return this.context.commit("setFetchAttachmentsUnsuccessful", response);

        return this.context.commit("setFetchAttachmentsSuccessful", response);
    }
}

export const attachmentModule: AttachmentModule = getModule(AttachmentModule);
