import { Vue, Component, Watch, Emit } from "vue-facing-decorator";
import { email, required, requiredIf } from "@vuelidate/validators";
import { useVuelidate, Validation } from "@vuelidate/core";
import { ref } from "vue";
import { authModule } from "@/store/auth.module";
import { userManager } from "@/managers/user.manager";
import { toastManager } from "@/managers/toast.manager";

@Component({
    emits: ["submit"],
    options: {
        validations() {
            return {
                email: {
                    required,
                    email,
                    $lazy: true
                },
                password: {
                    required,
                    $lazy: true
                },
                emailToSendPasswordReset: {
                    email,
                    requiredIf: requiredIf(this.forgotPassword),
                    $lazy: true
                }
            };
        }
    }
})
export default class LoginFormControlComponent extends Vue {
    private externalResults = ref({});

    public v$: Validation = useVuelidate({
        $externalResults: this.externalResults,
        $lazy: true
    }) as unknown as Validation;

    private readonly password = "";

    private readonly email = "";

    private readonly rememberInformation = false;

    forgotPassword: boolean = false;
    emailToSendPasswordReset: string = "";

    public get isLoggingIn() {
        return authModule.loggingIn;
    }

    public get authErrors() {
        return authModule.authErrors;
    }

    @Emit("submit")
    onSubmit(value: object) {
        return value;
    }

    @Watch("authErrors", { immediate: true, deep: true })
    public onAuthErrors(newValue: Error[]) {
        if (newValue && newValue.length > 0) {
            Object.assign(this.externalResults, {
                password: [this.$t("global.validations.login")]
            });
        }
    }

    public async submit() {
        const isFormCorrect = await this.v$.$validate();

        if (isFormCorrect) {
            return this.onSubmit({
                email: this.email,
                password: this.password,
                rememberInformation: this.rememberInformation
            });
        }
    }

    async sendPasswordResetAsync(): Promise<void> {
        const res = await userManager.sendPasswordResetAsync({ email: this.emailToSendPasswordReset });

        if (res instanceof Error) toastManager.error(this, this.$t("toast.error"), this.$t("toast.genericErrorMessage"));
        else this.forgotPassword = false;
    }
}
