import { VesselNameModel } from "@/models/models/vessel.name.model";
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";

@Component({})
export default class VesselHeaderComponent extends Vue {
    @Prop() vesselNumber!: string;

    @Prop() vesselNames!: VesselNameModel[];

    @Prop() aliases?: string;

    @Prop({ default: false }) isTechVessel?: boolean;

    @Prop() hide!: boolean;

    @Prop() isPartOfNavigation!: boolean;

    @Emit("onVesselEdit")
    editVessel(): void {}

    @Emit("onShowHistory")
    showHistory(): void {}

    created(): void {
        
    }

    getVesselName(vesselNames: VesselNameModel[], version: number): string {
        if (!vesselNames?.length) return "-";

        const maxVersion = Math.max(...vesselNames?.map(vn => vn.version));
        return this.parseEmpty(vesselNames.find(vn => vn.version == maxVersion - version)?.name);
    }

    private parseEmpty(value: string | number | null | undefined) {
        return value ? value.toString() : "-";
    }
}