import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";

export default (config: AxiosRequestConfig) => {
    const jsonApiHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json"
    } as AxiosRequestHeaders;

    Object.assign(config.headers as AxiosRequestHeaders, jsonApiHeaders);

    return config;
};
