import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "row mb-3" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = { class: "row mb-3" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = {
  key: 0,
  class: "p-error"
}
const _hoisted_10 = { class: "row mb-3" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "d-flex" }
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      visible: _ctx.showDialog,
      "onUpdate:visible": [
        _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showDialog) = $event)),
        _ctx.closed
      ],
      header: _ctx.$t('feedback.addFeedback'),
      draggable: false,
      modal: true,
      class: _normalizeClass(['p-fluid fw-bold feedback-dialog', _ctx.$route.name])
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('global.button.cancel'),
          icon: "pi pi-times",
          class: "secondary-button",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closed()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('global.button.send'),
          "data-test-id": "reading-value-save-button",
          icon: "pi pi-check",
          class: "primary-button",
          autofocus: "",
          onClick: _ctx.createFeedback
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("feedback.type")) + " *", 1),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.v$.feedback.feedbackType.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.feedback.feedbackType.$model) = $event)),
                "option-label": "text",
                "option-value": "value",
                class: _normalizeClass(['drop-down w-100 text-left', { 'p-invalid': _ctx.v$.feedback.feedbackType.$error }]),
                options: _ctx.feedbackTypes
              }, null, 8, ["modelValue", "class", "options"]),
              (_ctx.v$.feedback.feedbackType.$error)
                ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.v$.feedback.feedbackType.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("feedback.severity")) + " *", 1),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.v$.feedback.feedbackSeverity.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.feedback.feedbackSeverity.$model) = $event)),
                "option-label": "text",
                "option-value": "value",
                class: _normalizeClass(['drop-down w-100 text-left', { 'p-invalid': _ctx.v$.feedback.feedbackSeverity.$error }]),
                options: _ctx.feedbackSeverities
              }, null, 8, ["modelValue", "class", "options"]),
              (_ctx.v$.feedback.feedbackSeverity.$error)
                ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.v$.feedback.feedbackSeverity.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("feedback.comment")) + " *", 1),
              _createVNode(_component_Textarea, {
                modelValue: _ctx.v$.feedback.comment.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.feedback.comment.$model) = $event)),
                rows: "5",
                autofocus: "",
                class: _normalizeClass({ 'p-invalid': _ctx.v$.feedback.comment.$error })
              }, null, 8, ["modelValue", "class"]),
              (_ctx.v$.feedback.comment.$error)
                ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.v$.feedback.comment.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("feedback.image")), 1),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_FileUpload, {
                  class: "me-5 primary-button mt-2",
                  mode: "basic",
                  "custom-upload": true,
                  accept: "image/*",
                  auto: true,
                  "choose-label": _ctx.$t('feedback.chooseLabel'),
                  onUploader: _ctx.elPhotoLoader
                }, null, 8, ["choose-label", "onUploader"]),
                (_ctx.feedback.imageSource)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "user-photo cursor-pointer",
                      src: `data:image/jpeg;base64,${_ctx.feedback.imageSource}`,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.downloadImage(_ctx.feedback.imageSource)))
                    }, null, 8, _hoisted_13))
                  : _createCommentVNode("", true),
                (_ctx.feedback.imageSource)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      class: "mx-3 cursor-pointer",
                      src: require('../../../../public/icons/remove.svg'),
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.feedback.imageSource = null))
                    }, null, 8, _hoisted_14))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "header", "class", "onUpdate:visible"]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _normalizeProps(_guardReactiveProps(_ctx.componentData)), null, 16))
  ]))
}