import { GlobalTableModel } from "./global.table.model";
import { ConfigType } from "../enums/config.type";

export class VesselModelModel {
    id?: number;

    numberOfUnits!: number;

    vesselModelName!: GlobalTableModel;

    config!: ConfigType;

    waterJetType!: GlobalTableModel;

    shaft!: number;

    engineMaker!: GlobalTableModel;

    engineModel!: GlobalTableModel;

    gearBoxMake!: GlobalTableModel;

    gearBoxRatio!: GlobalTableModel;

    grid!: string;
}
