import httpClient from "../utilities/helpers/http.client.axios";
import { AxiosInstance, AxiosResponse } from "axios";
import { ResponseEventLogModel } from "@/models/response.models/response.eventLog.model";
import { RequestUpdateStatusEventLogModel } from "@/models/request.models/request.update.status.event.log.model";
import { plainToInstance } from "class-transformer";
import { EventLogExportModel } from "@/models/models/event.log.export.model";
import { excelFileHelper } from "@/utilities/helpers/excel.download.helper";
import { RequestEventLogBulkModel } from "@/models/request.models/request.event.log.bulk.model";
import { RequestEventLogModel } from "@/models/request.models/request.event.log.model";
import RequestBulkEventLogModel from "@/models/request.models/request.bulk.event.log.model";
import ResponseEventLogMultiMediaModel from "@/models/response.models/response.event.log.multi.media.model";
import EventLogStatusModel from "@/models/models/eventLog/event.log.status.model";
import EventLogTypeModel from "@/models/models/eventLog/event.log.type.model";
import Query from "@/models/models/query";

export class EventLogManager {
    private readonly api: AxiosInstance = httpClient;

    public async getEventLogs(vesselId: number): Promise<ResponseEventLogModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseEventLogModel[]> = await this.api.get(`/EventLog/GetEventLogs/${vesselId}`);
            return plainToInstance(ResponseEventLogModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async getEventLogsForAgentAsync(agentId: number): Promise<ResponseEventLogModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseEventLogModel[]> = await this.api.get(`/EventLog/EventLogs/${agentId}`);
            return plainToInstance(ResponseEventLogModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async getEventLog(id: number): Promise<ResponseEventLogModel | Error> {
        try {
            const response: AxiosResponse<ResponseEventLogModel> = await this.api.get(`/EventLog/GetEventLog/${id}`);

            return plainToInstance(ResponseEventLogModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async updateEventLogStatus(requestUpdateEventLogModel: RequestUpdateStatusEventLogModel) {
        try {
            const response: AxiosResponse<ResponseEventLogModel> = await this.api.put("/EventLog/UpdateEventLogStatus", requestUpdateEventLogModel);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async saveEventLog(requestEventLogModel: RequestEventLogModel): Promise<ResponseEventLogModel | Error> {
        try {
            const response: AxiosResponse<ResponseEventLogModel> = await this.api.post("/EventLog/SaveEventLog", requestEventLogModel);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async bulkCreateEventLogAsync(model: RequestBulkEventLogModel): Promise<ResponseEventLogModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseEventLogModel[]> = await this.api.post(`/EventLog/BulkCreateEventLog`, model);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async bulkUpdateEventLog(model: RequestEventLogBulkModel): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.post(`/EventLog/BulkUpdateEventLog`, model);

            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async deleteEventLog(id: number) {
        try {
            const response = await this.api.delete(`/EventLog/DeleteEventLog/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async ExportToXlsxAsync(requestEventLogExportModel: EventLogExportModel[]) {
        try {
            const response = await this.api.post("/EventLog/ExportEventLogs", requestEventLogExportModel, {
                responseType: "blob"
            });

            excelFileHelper.download(response, "ExportedEventLogs.xlsx");

            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getEventLogsByQueryAsync(query: Query): Promise<ResponseEventLogModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseEventLogModel[]> = await this.api.post(`/EventLog/GetEventLogsByQuery`, {
                ...query,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            });
            return plainToInstance(ResponseEventLogModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async createMultiMediaAsync(requestAttachment: FormData): Promise<void | Error> {
        try {
            const response = await this.api.post("/EventLog/CreateMultiMedia", requestAttachment);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getMultiMediaAsync(id: number): Promise<ResponseEventLogMultiMediaModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseEventLogMultiMediaModel[]> = await this.api.get(`/EventLog/GetMultiMedia/${id}`);
            return plainToInstance(ResponseEventLogMultiMediaModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async getMultiMediaContentAsync(attachmentId: number, code: string | null = null): Promise<string | Error> {
        try {
            const response = await this.api.get(`/EventLog/GetMultiMediaContent/${attachmentId}?code=${code}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getQrCodeParamsAsync(eventId: number): Promise<string | Error> {
        try {
            const response = await this.api.get(`/EventLog/GetQrCodeParams/${eventId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async deleteMultiMediaAsync(id: number): Promise<boolean | Error> {
        try {
            const response = await this.api.delete(`/EventLog/DeleteMultiMedia/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getEventLogStatusesAsync(): Promise<EventLogStatusModel[] | Error> {
        try {
            const response: AxiosResponse<EventLogStatusModel[]> = await this.api.get("EventLog/GetEventLogStatuses");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getEventLogTypesAsync(): Promise<EventLogTypeModel[] | Error> {
        try {
            const response: AxiosResponse<EventLogTypeModel[]> = await this.api.get("EventLog/GetEventLogTypes");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}

export const eventLogManager = new EventLogManager();
