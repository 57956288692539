import { AxiosInstance, AxiosResponse } from "axios";
import httpclient from "../utilities/helpers/http.client.axios";
import { ResponseServiceModel } from "@/models/response.models/response.service.model";
import { RequestServiceModel } from "@/models/request.models/request.service.model";
import { plainToInstance } from "class-transformer";
import { ResponseTechnicianServiceModel } from "@/models/response.models/response.technician.service.model";
import { ServiceExportModel } from "@/models/models/service.export.model";
import { excelFileHelper } from "@/utilities/helpers/excel.download.helper";
import RequestAttachEventToServiceModel from "@/models/request.models/request.attach.event.to.service.model";
import RequestServiceViaSetModel from "@/models/request.models/request.service.via.set.model";
import ServiceLocationModel from "@/models/models/service.location.model";
import ServiceRequestedByModel from "@/models/models/service.requested.by.model";
import BaseModel from "@/models/models/base/base.model";
import Query from "@/models/models/query";

export class ServiceManager {
    private readonly api: AxiosInstance = httpclient;

    async getServicesForVessel(vesselId: number): Promise<ResponseServiceModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseServiceModel[]> = await this.api.get(`/Service/GetServicesForVessel/${vesselId}`);

            return plainToInstance(ResponseServiceModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async getSlimServicesForVesselAsync(vesselId: number): Promise<ResponseServiceModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseServiceModel[]> = await this.api.get(`/Service/GetSlimServicesForVessel/${vesselId}`);
            return plainToInstance(ResponseServiceModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async getAgentServicesAsync(agentId: number): Promise<ResponseServiceModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseServiceModel[]> = await this.api.get(`/Service/Services/${agentId}`);
            return plainToInstance(ResponseServiceModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async createService(model: RequestServiceModel): Promise<ResponseServiceModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseServiceModel[]> = await this.api.post(`/Service/CreateService`, model);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async createServicesViaTemplateSetAsync(request: RequestServiceViaSetModel): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.post(`/Service/CreateServicesViaTemplateSet`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getServicesForTechnician(vesselId: number): Promise<ResponseServiceModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseServiceModel[]> = await this.api.get(`/Service/GetServicesForTechnician/${vesselId}`);
            return plainToInstance(ResponseServiceModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async updateServiceByTechnicianAsync(RequestServiceDataModel: RequestServiceModel) {
        try {
            const response: AxiosResponse<ResponseServiceModel> = await this.api.put("/Service/UpdateServiceByTechnician", RequestServiceDataModel);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getServiceFullDataById(serviceId: number, includePersonnel: boolean): Promise<ResponseServiceModel | Error> {
        try {
            const response: AxiosResponse<ResponseServiceModel> = await this.api.get(`/Service/GetServiceFullDataById/${serviceId}/${includePersonnel}`);
            return plainToInstance(ResponseServiceModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async getServiceByQrCodeAsync(code: string): Promise<ResponseServiceModel | Error> {
        try {
            const response: AxiosResponse<ResponseServiceModel> = await this.api.get(`/Service/GetServiceByQrCode/${code}`);
            return plainToInstance(ResponseServiceModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async showMjpCommentsAsync(code: string): Promise<boolean | Error> {
        try {
            const response: AxiosResponse<boolean> = await this.api.get(`/Service/ShowMjpComments/${code}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async showImagesAsync(code: string): Promise<boolean | Error> {
        try {
            const response: AxiosResponse<boolean> = await this.api.get(`/Service/ShowImages/${code}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateServiceAsync(request: RequestServiceModel) {
        try {
            const response: AxiosResponse<ResponseServiceModel> = await this.api.put("/Service/UpdateService", request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateServiceLocationAsync(request: ServiceLocationModel): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.put("/Service/ServiceLocation", request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateRequestedByAsync(request: ServiceRequestedByModel): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.put("/Service/RequestedBy", request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async deleteService(id: number) {
        try {
            const response = await this.api.delete(`/Service/DeleteService/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async GetReportAccessCode(id: number): Promise<string | Error> {
        try {
            const response = await this.api.get(`/Service/GetReportAccessCode/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getServicesAsync(query: Query): Promise<ResponseTechnicianServiceModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseTechnicianServiceModel[]> = await this.api.post("/Service/GetServices", {
                ...query,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            });
            return plainToInstance(ResponseTechnicianServiceModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async getServiceReportFormsAsync(query: Query): Promise<ResponseTechnicianServiceModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseTechnicianServiceModel[]> = await this.api.post("/Service/GetServiceReportForms", {
                ...query,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            });
            return plainToInstance(ResponseTechnicianServiceModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async exportToXls(request: ServiceExportModel[]) {
        try {
            const response = await this.api.post("/Service/ExportServices", request, { responseType: "blob" });
            excelFileHelper.download(response, "ExportedServices.xlsx");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async attachEventLogsToServiceAsync(requests: RequestAttachEventToServiceModel[]): Promise<boolean | Error> {
        try {
            const response: AxiosResponse<boolean> = await this.api.post("/Service/AttachEventsToService", requests);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async severEventLogsFromServiceAsync(requests: RequestAttachEventToServiceModel[]): Promise<boolean | Error> {
        try {
            const response: AxiosResponse<boolean> = await this.api.post("/Service/SeverEventsFromService", requests);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getServiceTypesAsync(): Promise<BaseModel[] | Error> {
        try {
            const response: AxiosResponse<BaseModel[]> = await this.api.get("/Service/Types");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getServiceNecessitiesAsync(): Promise<BaseModel[] | Error> {
        try {
            const response: AxiosResponse<BaseModel[]> = await this.api.get("/Service/Necessities");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}

export const serviceManager = new ServiceManager();
