import { Type } from "class-transformer";
import { PartHistoryActionType } from "../enums/part.history.action.type";

export class ResponseModifiedPartModel {
    id!: number;

    partNumber!: string;

    fullPath!: string;

    partDescription?: string;

    actionType?: PartHistoryActionType;

    @Type(() => Date)
    createdDate!: Date;
}
