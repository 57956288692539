import { AxiosInstance, AxiosResponse } from "axios";
import httpclient from "../utilities/helpers/http.client.axios";
import SparePartOrderModel from "@/models/models/spare.part.order/spare.part.order.model";
import ServiceKitModel from "@/models/models/service.kit/service.kit.model";
import RequestServiceKitModel from "@/models/request.models/request.service.kit.model";
import { DrawingData } from "@/models/models/drawing.data";
import BaseModel from "@/models/models/base/base.model";
import EngineSizeModel from "@/models/models/service.kit/engine.size.model";
import ServiceKitVesselModel from "@/models/models/service.kit/service.kit.vessel.model";

export class ServiceKitManager {
    private readonly api: AxiosInstance = httpclient;

    async getSparePartOrdersAsync(vesselId: number): Promise<SparePartOrderModel[] | Error> {
        try {
            const response: AxiosResponse<SparePartOrderModel[]> = await this.api.get(`/ServiceKit/SparePartOrders/${vesselId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getSparePartOrderAsync(id: number): Promise<SparePartOrderModel | Error> {
        try {
            const response: AxiosResponse<SparePartOrderModel> = await this.api.get(`/ServiceKit/SparePartOrder/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getAgentSparePartOrdersAsync(agentId: number): Promise<SparePartOrderModel[] | Error> {
        try {
            const response: AxiosResponse<SparePartOrderModel[]> = await this.api.get(`/ServiceKit/Orders/${agentId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getAgentSpecificSparePartOrdersAsync(agentId: number, vesselId?: number): Promise<SparePartOrderModel[] | Error> {
        try {
            const response: AxiosResponse<SparePartOrderModel[]> = await this.api.get(`/ServiceKit/AgentSpecificOrders/${agentId}/${vesselId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async synchronizeAsync(): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.post(`/ServiceKit/Synchronize`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getServiceKitsAsync(request: RequestServiceKitModel): Promise<ServiceKitModel[] | Error> {
        try {
            const response: AxiosResponse<ServiceKitModel[]> = await this.api.post(`/ServiceKit/ServiceKits`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getDrawingDataAsync(id: bigint): Promise<DrawingData | Error> {
        try {
            const response: AxiosResponse<DrawingData> = await this.api.get(`/ServiceKit/DrawingData/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getAllServiceKitsAsync(): Promise<ServiceKitModel[] | Error> {
        try {
            const response: AxiosResponse<ServiceKitModel[]> = await this.api.get(`/ServiceKit/AllServiceKits`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateServiceKitMetaDataAsync(request: ServiceKitModel): Promise<ServiceKitModel | Error> {
        try {
            const response: AxiosResponse<ServiceKitModel> = await this.api.put(`/ServiceKit/Update`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async toggleExcludeFromNewVesselsAsync(id: number, val: boolean): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.put(`/ServiceKit/ToggleExcludeFromNewVessels/${id}/${val}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getModelsAsync(): Promise<BaseModel[] | Error> {
        try {
            const response: AxiosResponse<BaseModel[]> = await this.api.get(`/ServiceKit/EngineModels`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getSizesAsync(): Promise<EngineSizeModel[] | Error> {
        try {
            const response: AxiosResponse<EngineSizeModel[]> = await this.api.get(`/ServiceKit/EngineSizes`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getVesselsAsync(id: number): Promise<ServiceKitVesselModel[] | Error> {
        try {
            const response: AxiosResponse<ServiceKitVesselModel[]> = await this.api.get(`/ServiceKit/Vessels/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateServiceKitVesselsAsync(requests: ServiceKitVesselModel[]): Promise<ServiceKitVesselModel[] | Error> {
        try {
            const response: AxiosResponse<ServiceKitVesselModel[]> = await this.api.put(`/ServiceKit/UpdateVessels`, requests);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    
    async removeServiceKitInstanceAsync(kitId: number, vesselId: number): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.delete(`/ServiceKit/ServiceKitInstance/${kitId}/${vesselId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}

export const serviceKitManager = new ServiceKitManager();
