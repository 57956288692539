import { UserRoles } from "@/models/enums/user.roles";
import { ContactModel } from "@/models/models/contact.model";
import { UserModel } from "@/models/models/user.model";

export const isAdmin = (userRole?: UserRoles): boolean => {
    if (!userRole) {
        return false;
    }

    return (userRole & UserRoles.MjpAdmin) === UserRoles.MjpAdmin || (userRole & UserRoles.SuperAdmin) === UserRoles.SuperAdmin;
};
export const isUser = (userRole?: UserRoles): boolean => {
    if (!userRole) {
        return false;
    }

    return (userRole & UserRoles.MjpUser) === UserRoles.MjpUser || (userRole & UserRoles.SpUser) === UserRoles.SpUser;
};
export const isSpUser = (userRole?: UserRoles): boolean => {
    if (!userRole) {
        return false;
    }

    return (userRole & UserRoles.SpUser) === UserRoles.SpUser;
};
export const isMjp = (userRole?: UserRoles): boolean => {
    if (!userRole) return false;

    return ((userRole & UserRoles.MjpUser) === UserRoles.MjpUser || (userRole & UserRoles.MjpAdmin) === UserRoles.MjpAdmin);
};

export const isMjpUser = (userRole?: UserRoles): boolean => {
    if (!userRole) return false;

    return (userRole & UserRoles.MjpUser) === UserRoles.MjpUser;
};

export const isMjpAgent = (user: UserModel): boolean => {
    return user.serviceProviderName !== null && user.serviceProviderName.toLowerCase().trim() === "marine jet power ab";
}

export const isAgentMjp = (agent: ContactModel): boolean => {
    return agent.name.toLowerCase().trim() === "marine jet power ab";
}

export const isSuperAdmin = (userRole?: UserRoles): boolean => {
    if (!userRole) {
        return false;
    }

    return (userRole & UserRoles.SuperAdmin) === UserRoles.SuperAdmin;
};
