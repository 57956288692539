import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ae00be7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-100 text-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "mw-100 h-100 mb-1",
  controls: ""
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      visible: _ctx.showDialog,
      "onUpdate:visible": [
        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDialog) = $event)),
        _ctx.closed
      ],
      draggable: false,
      modal: true,
      class: _normalizeClass(['p-fluid fw-bold dialog-70-90', _ctx.$route.name]),
      header: _ctx.$t('global.downloadHeader')
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "primary-button",
          label: _ctx.$t('vessel.attachment.download'),
          icon: "pi pi-download",
          "icon-pos": "left",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadContent()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('global.button.close'),
          icon: "pi pi-times",
          class: "secondary-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closed()))
        }, null, 8, ["label"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.isImage)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                class: "mw-100 h-100 mb-1",
                src: _ctx.createUrl()
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          (!_ctx.isImage)
            ? (_openBlock(), _createElementBlock("video", _hoisted_3, [
                _createElementVNode("source", {
                  src: _ctx.createUrl()
                }, null, 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible", "class", "header", "onUpdate:visible"])
  ]))
}