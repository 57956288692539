<template>
    <div id="tree-table">
        <div class="row">
            <div class="col-xxl-6 mb-3">
                <span
                    v-if="showGlobalSearch"
                    :class="['p-input-icon-left text-nowrap', { 'd-flex justify-content-between': isMobileView }]">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global']" class="search-box" :placeholder="$t('table.search')" />
                    <Button
                        class="secondary-button"
                        :disabled="!filters['global'].length"
                        @click="clear()"
                        @keydown.enter="clear()">
                        {{ $t("global.button.clear") }}
                    </Button>
                </span>
            </div>
            <div class="col-xxl-6 mb-3 d-flex justify-content-end ps-0">
                <slot name="header"></slot>
                <Button
                    type="button"
                    :class="['secondary-button mx-3', isTechVesselPage ? 'tech-page' : 'service-page']"
                    icon="pi pi-plus"
                    :label="$t(isMobileView ? 'table.expandAllShort' : 'table.expandAll')"
                    @click="expandAll" />
                <Button
                    type="button"
                    :class="['secondary-button', isTechVesselPage ? 'tech-page' : 'service-page']"
                    icon="pi pi-minus"
                    :label="$t(isMobileView ? 'table.collapseAllShort' : 'table.collapseAll')"
                    @click="collapseAll" />
            </div>
        </div>

        <TreeTable
            v-model:expandedKeys="expandedKeys"
            :value="value"
            :resizable-columns="true"
            :class="['p-treetable-sm', $route.name]"
            :filters="filters"
            :indentation="2"
            :loading="isLoading"
            selection-mode="single"
            @update:selectionKeys="selectRow"
            @filter="onFilter">
            <template #loading> {{ loadingText }}</template>
            <template #empty><slot name="empty"></slot></template>
            <!-- <Column key="id" :header-style="'width:40px'">
                <template #body="{ node }">
                    <Checkbox :binary="true" class="select-item" @change="select(node.data)" />
                </template>
            </Column> -->
            <Column
                v-for="(columnDefinition, index) of table.columns"
                :key="columnDefinition.fieldName"
                :field="columnDefinition.fieldName"
                :sortable="!!columnDefinition.sortField"
                :sort-field="columnDefinition.sortField"
                :header-style="'width:' + columnDefinition.width"
                :expander="columnDefinition.expander"
                :header="columnDefinition.header">
                <template #body="{ node }">
                    <span
                        v-if="columnDefinition.type == columnType.Date"
                        v-tooltip.bottom="{ value: node.data[columnDefinition.fieldName] }"
                        :class="{ 'row-highlighted': hasSearchText(node.data[columnDefinition.fieldName]) }">
                        {{ node.data[columnDefinition.fieldName].toLocaleDateString() }}
                    </span>
                    <div v-else-if="columnDefinition.type == columnType.Menu" class="text-center">
                        <i
                            class="pi pi-ellipsis-v p-2"
                            @click="ev => toggle(ev, node.data[columnDefinition.fieldName], $refs)" />
                        <Menu
                            :id="table.tableId"
                            :ref="`menu${node.data[columnDefinition.fieldName]}`"
                            :model="columnDefinition.additionalData.getItems(node)"
                            class="py-3"
                            :popup="true">
                            <template #item="{ item }">
                                <div class="px-4 py-2">
                                    <button
                                        class="w-100 p-link flex align-items-center pl-4 text-color border-noround"
                                        @click="item.command">
                                        <img :src="item.icon" class="align-baseline" />
                                        <span class="ms-3"> {{ item.label }} </span>
                                        <div v-if="item.subLabel" class="sub-label">
                                            {{ item.subLabel }}
                                        </div>
                                    </button>
                                </div>
                            </template>
                        </Menu>
                    </div>
                    <span
                        v-else
                        :class="{ 'row-highlighted': hasSearchText(node.data[columnDefinition.fieldName]) }"
                        @mouseover="() => showClipboardIcon(node.data.id, index)"
                        @mouseout="() => hideClipboardIcon(null, null)">
                        {{ node.data[columnDefinition.fieldName] }}

                        <i
                            v-if="showClipboardId === node.data.id && showClipboardIndex === index"
                            :class="
                                showClipboardId === node.data.id && showClipboardIndex === index && isHoveringClipboard
                                    ? 'pi pi-copy clipboard-icon-container'
                                    : 'pi pi-copy'
                            "
                            @mouseenter="isHoveringClipboard = true"
                            @mouseout="isHoveringClipboard = false"
                            @click="event => copyValueToClipboardAsync(event, node.data[columnDefinition.fieldName])"></i>
                    </span>
                </template>
            </Column>
        </TreeTable>
    </div>
</template>
<script src="./tree.table.ts"></script>
<style lang="scss" scoped src="./tree.table.scoped.scss"></style>
<style lang="scss" src="./tree.table.scss"></style>
