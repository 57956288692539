import { UserRoles } from "@/models/enums/user.roles";
import { RequestLoginModel } from "@/models/request.models/request.login.model";
import router from "../../../../router";
import { authModule } from "@/store/auth.module";
import { Component } from "vue-facing-decorator";
import LoginFormControl from "../../../complex.controls/login/login.form.control.vue";
import { toastManager } from "@/managers/toast.manager";
import BaseControl from "@/components/complex.controls/base.control";

@Component({
    components: {
        LoginFormControl
    }
})
export default class LoginPageControl extends BaseControl {
    public async onSubmitLogin(requestLoginModel: RequestLoginModel): Promise<void> {
        await authModule.login(requestLoginModel);
        const role = authModule.currentUser?.role;

        if (!authModule.currentUser) toastManager.error(this, this.$t("toast.warn"), this.$t("user.loginFailed"))

        if (authModule.isAuthorized) {
            switch (role) {
                case UserRoles.SuperAdmin: {
                    await router.push({ path: "/admin" });
                    break;
                }
                case UserRoles.MjpAdmin:
                case UserRoles.MjpUser:{
                    await router.push({ path: "/fleet" });
                    break;
                }
                case UserRoles.SpUser: {
                    await router.push(`/service-agent/${authModule.currentUser?.serviceProviderId}`);
                    break;
                }
                default: {
                    await router.push({ path: "/login" });
                    break;
                }
            }
        }
    }
}
