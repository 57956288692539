import { Type } from "class-transformer";
import { EventStatus } from "../enums/event.status";
import { UserModel } from "../models/user.model";
import { ResponseServiceModel } from "@/models/response.models/response.service.model";
import EventLogStatusModel from "@/models/models/eventLog/event.log.status.model";
import EventLogTypeModel from "@/models/models/eventLog/event.log.type.model";

export class ResponseEventLogModel {
    public id!: number;

    public title!: string;

    public description!: string;

    @Type(() => Date)
    public creationDate!: Date;

    get creationDateString(): string {
        return this.creationDate.toLocaleString();
    }

    get shortCreationDateString(): string {
        return this.creationDate.toLocaleDateString();
    }

    public vesselId!: number;

    public vesselName!: string;

    public vesselNumber!: string;

    public createdByUser!: UserModel;

    public eventStatus!: EventStatus;

    public status!: EventLogStatusModel;

    public type!: EventLogTypeModel;

    public typeName!: string;

    public serviceId?: number;

    public imageSource?: string;

    public handler?: UserModel;

    public handlerId?: number = this.handler?.id;

    public handlerFullName?: string;

    public serviceProviderId?: number;

    public responsibleMjpUsers!: UserModel[];

    public responsibleSpUsers!: UserModel[];

    public connectedServices!: ResponseServiceModel[];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [others: string]: unknown;
}
