import { Component } from "vue-facing-decorator";
import { authModule } from "@/store/auth.module";
import { UserRoles } from "@/models/enums/user.roles";
import { isAdmin, isUser, isMjpUser } from "@/utilities/helpers/user.role.helper";
import BaseControl from "../base.control";

@Component({})
export default class NavigationBarControlComponent extends BaseControl {
    role = UserRoles;

    showMenu: boolean = false;

    isDashboardActive: boolean = false;
    isWikiSectionActive: boolean = false;

    public get menuBarItems() {
        if (isMjpUser(this.userRole)) return this.mjpMenuBarItems();

        if (isAdmin(this.userRole)) return this.adminMenuBarItems();

        if (isUser(this.userRole)) return this.userMenuBarItems();

        return [];
    }

    public get userMenuItems() {
        return [
            {
                label: `Version ${process.env.VUE_APP_VERSION}`,
                clickable: false
            },
            {
                label: "Logout",
                icon: "pi mx-1 pi-sign-out",
                clickable: true,
                command: async () => await this.logout()
            }
        ];
    }

    get userRole(): UserRoles {
        return authModule.currentUser?.role ?? UserRoles.None;
    }

    get userAccount(): string {
        return authModule.currentUser?.fullName ?? "";
    }

    beforeMount(): void {
        this.$router.beforeEach(guard => {
            this.isDashboardActive = guard.name === "Dashboard";
            this.isWikiSectionActive = guard.name === "Help";
        });
    }

    created(): void {   
        document.addEventListener("click", e => {
            const popupContainer = document.getElementById("n-end");
            const base = document.getElementById("n-user-menu");
            const popup = document.getElementById("n-user-menu-content");

            if (base && popupContainer && popup) if (e.target !== base && !popupContainer!.contains(e.target as Node) && !popup!.contains(e.target as Node)) this.showMenu = false;
        });
    }

    toggleUserMenu(): void {
        this.showMenu = !this.showMenu;
    }

    userMenuBarItems() {
        return [ ];
    }

    adminMenuBarItems() {
        return [
            {
                label: this.$t("navbar.fleet"),
                to: "/fleet",
                svg: require("../../../../public/icons/fleet.svg")
            },
            {
                label: this.$t("navbar.events"),
                to: "/",
                svg: require("../../../../public/icons/events.svg")
            },
            {
                label: this.$t("navbar.serviceReport"),
                to: "/serviceReports",
                svg: require("../../../../public/icons/services.svg")
            },
            {
                label: this.$t("navbar.serviceProviders"),
                to: "/service-agents",
                svg: require("../../../../public/icons/agents.svg")
            },
            {
                label: this.$t("navbar.admin"),
                to: "/admin",
                svg: require("../../../../public/icons/admin.svg")
            }
        ];
    }

    mjpMenuBarItems() {
        return [
            {
                label: this.$t("navbar.fleet"),
                to: "/fleet",
                svg: require("../../../../public/icons/fleet.svg")
            },
            {
                label: this.$t("navbar.events"),
                to: "/",
                svg: require("../../../../public/icons/events.svg")
            },
            {
                label: this.$t("navbar.serviceReport"),
                to: "/serviceReports",
                svg: require("../../../../public/icons/services.svg")
            },
            {
                label: this.$t("navbar.serviceProviders"),
                to: "/service-agents",
                svg: require("../../../../public/icons/agents.svg")
            },
        ];
    }


    async logout(): Promise<void> {
        await authModule.logout();
        this.$router.go(0);
    }

    async toHomePageAsync(): Promise<void> {
        if (authModule.currentUser?.role === UserRoles.SpUser) await this.$router.push(`/service-agent/${authModule.currentUser.serviceProviderId}`);
        else await this.$router.push("/");
    }

    async goToDashboardAsync(): Promise<void> {
        await this.$router.push("/dashboard");
    }

    async goToWikiSectionAsync(): Promise<void> {
        await this.$router.push("/help");
    }

    isMjpOrMjpAdmin(): boolean {
        return isMjpUser(this.userRole) || isAdmin(this.userRole);
    }
}
