import { Type } from "class-transformer";
import { GlobalTableModel } from "./global.table.model";
import { ResponseBaseModel } from "../response.models/response.base.model";
import { OperatorModel } from "../response.models/operator.model";
import { ShipyardModel } from "./shipyard.model";
import { VesselModelModel } from "./vessel.model.model";
import { VesselNameModel } from "./vessel.name.model";

export class VesselAdditionalInfoModel extends ResponseBaseModel {
    deliveryYear!: number;

    ownershipType!: GlobalTableModel;

    application!: GlobalTableModel;

    hullType!: GlobalTableModel;

    currentShipyard = new ShipyardModel();

    buyerCode!: string;

    hullNumber!: string;

    speed!: number;

    length!: number;

    numberUnits!: number;

    vesselModels!: VesselModelModel[];

    internationalMaritimeOrganizationNumber!: number;

    maritimeMobileServiceIdentity!: number;

    vesselNames!: VesselNameModel[];

    hullMaterial!: GlobalTableModel;

    intakeMaterial!: GlobalTableModel;

    classId!: string;

    comment!: string;

    vesselStatus?: GlobalTableModel;

    controlSystemType!: GlobalTableModel;

    @Type(() => Date)
    commissionDate!: Date;

    vesselId!: number;
}
