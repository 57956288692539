import { AxiosInstance, AxiosResponse } from "axios";
import { ResponseAttachmentModel } from "@/models/response.models/response.attachment.model";
import { fileHelper } from "@/utilities/helpers/file.helper";
import httpclient from "../utilities/helpers/http.client.axios";
import { plainToInstance } from "class-transformer";

export class AttachmentManager {
    private readonly api: AxiosInstance = httpclient;

    async getAttachmentsByVesselId(vesselId: number): Promise<ResponseAttachmentModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseAttachmentModel[]> = await this.api.get(`/Attachment/GetAttachmentsByVesselId/${vesselId}`);
            return plainToInstance(ResponseAttachmentModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async viewAttachmentAsync(id: number): Promise<void | Error> {
        try {
            const response = await this.api.get(`/Attachment/GetAttachmentContentById/${id}`, { responseType: "blob" });
            const url = window.URL.createObjectURL(response.data);
            window.open(url, "_blank");
        } catch (error) {
            return error as Error;
        }
    }

    async downloadAttachmentAsync(id: number, fileName: string): Promise<void | Error> {
        try {
            const response = await this.api.get(`/Attachment/GetAttachmentContentById/${id}`, { responseType: "blob" });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            fileHelper.download(url, fileName);
        } catch (error) {
            return error as Error;
        }
    }

    async createAttachmentsAsync(request: FormData): Promise<void | Error> {
        try {
            const response = await this.api.post("/Attachment/CreateAttachments", request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async deleteAttachment(id: number) {
        try {
            const response = await this.api.delete(`/Attachment/DeleteAttachment/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateIsPublicAsync(id: number, isPublic: boolean): Promise<void | Error> {
        try {
            const response = await this.api.put(`/Attachment/ToggleIsPublic/${id}/${isPublic}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}
export const attachmentManager = new AttachmentManager();
