import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import httpClient from "../utilities/helpers/http.client.axios";
import TemplateModel from "@/models/models/template/template.model";

export class TemplateManager {
  private readonly api: AxiosInstance = httpClient;

  public async getTemplatesAsync(): Promise<TemplateModel[] | AxiosError> {
    try {
      const response: AxiosResponse<TemplateModel[]> = await this.api.get("/Template/Templates");
      return response.data;
    } catch (error) {
      return error as AxiosError;
    }
  }

  public async getTemplateAsync(id: number): Promise<TemplateModel | AxiosError> {
    try {
      const response: AxiosResponse<TemplateModel> = await this.api.get(`/Template/Template/${id}`);
      return response.data;
    } catch (error) {
      return error as AxiosError;
    }
  }

  public async createTemplateAsync(request: TemplateModel): Promise<unknown | AxiosError> {
    try {
      const response: AxiosResponse<unknown> = await this.api.post(`/Template/Create`, request);
      return response.data;
    } catch (error) {
      return error as AxiosError;
    }
  }

  public async updateTemplateAsync(request: TemplateModel): Promise<unknown | AxiosError> {
    try {
      const response: AxiosResponse<unknown> = await this.api.put(`/Template/Update`, request);
      return response.data;
    } catch (error) {
      return error as AxiosError;
    }
  }

  public async deleteTemplateAsync(id: number): Promise<unknown | AxiosError> {
    try {
      const response: AxiosResponse<unknown> = await this.api.delete(`/Template/Template/${id}`);
      return response.data;
    } catch (error) {
      return error as AxiosError;
    }
  }
}

export const templateManager = new TemplateManager();