import { Type } from "class-transformer";
import { UserModel } from "../models/user.model";
import { ResponseBaseModel } from "./response.base.model";

export class ResponseAttachmentModel extends ResponseBaseModel {
    public fileName!: string;

    public contentType!: string;

    @Type(() => Date)
    public lastUpdate!: Date;

    public createdByUser!: UserModel;

    public isPublic!: boolean;

    [others: string]: unknown;
}
