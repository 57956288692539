import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { accessTokenKey } from "../constants/auth.data";

export default (config: AxiosRequestConfig) => {
    const requestHeaders: AxiosRequestHeaders = {} as AxiosRequestHeaders;

    const accessToken = sessionStorage.getItem(accessTokenKey) ?? localStorage.getItem(accessTokenKey);

    if (accessToken) {
        requestHeaders.Authorization = `Bearer ${accessToken}`;

        Object.assign(config.headers as AxiosRequestHeaders, requestHeaders);
    }

    return config;
};
