import jwtDecode, { JwtPayload } from "jwt-decode";

export const isTokenExpired = (jwtToken: string): boolean => {
    if (!jwtToken) {
        return false;
    }

    const sec = (jwtDecode(jwtToken) as JwtPayload).exp as number;
    const isExpired = Date.now() >= sec * 1000;

    return isExpired;
};
