import { view } from "@/utilities/helpers/mobile.helper";
import { Vue, ComponentBase } from "vue-facing-decorator";
// import isMobile from "ismobilejs";

@ComponentBase
export default class BaseControl extends Vue {
    component: string | null = null;
    componentData: object | null = null;
    canAccess: boolean = false;

    get isMobileView(): boolean {
        return screen.width < 768;
        // return isMobile(window.navigator).any;
    }

    get isTabletView(): boolean {
        return view.isTabletView();
    }

    setSubComponent(component: string, componentData: object | null): void {
        this.componentData = componentData;
        this.component = component;
    }

     setAccess(access: boolean): void {
        this.canAccess = access;
    }
}
