import { VuexModule } from "vuex-module-decorators";

export interface IModuleState {
    errors: Error[];
    isLoading: boolean;
}

export class BaseModule extends VuexModule implements IModuleState {
    public errors: Error[] = [];
    public isLoading = false;
}
