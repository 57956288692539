import { UserModel } from "../models/user.model";
import { ResponseBaseModel } from "./response.base.model";
import { VesselFullModel } from "../models/vessel.full.model";
import { Type } from "class-transformer";

export default class ResponseOperatingProfileModel extends ResponseBaseModel {
    vesselId!: number;

    vessel!: VesselFullModel;

    @Type(() => Date)
    date!: Date;

    runningHours!: number;

    createdByUserId!: number;

    createdByUser!: UserModel;
}
