import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f1b978e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vessel-number-container" }
const _hoisted_2 = { class: "vessel-number-alias" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (!_ctx.hide)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['header-edit', _ctx.isPartOfNavigation ? 'slide-down-animation' : '']),
        style: _normalizeStyle({ padding: _ctx.isPartOfNavigation ? '2rem 2.5rem 2rem 2.9rem' : '2rem 2rem 0 0.5rem' })
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.getVesselName(_ctx.vesselNames, 0)), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("vessel.field.vesselNumber")), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.vesselNumber), 1),
            (!_ctx.isTechVessel && _ctx.aliases !== '')
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "|"))
              : _createCommentVNode("", true),
            (!_ctx.isTechVessel && _ctx.aliases !== '')
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.aliases), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        (!_ctx.isTechVessel)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.$t('global.button.edit'),
              class: "edit-button not-printing",
              onClick: _ctx.editVessel
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true),
        false
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              icon: "pi pi-clock",
              class: "edit-button",
              style: {"margin-left":"0.5rem"},
              onClick: _ctx.showHistory
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ], 6))
    : _createCommentVNode("", true)
}