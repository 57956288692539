import { AxiosInstance, AxiosResponse } from "axios";
import httpclient from "../utilities/helpers/http.client.axios";
import ResponseOperatingProfileModel from "../models/response.models/response.operating.profile.model";
import { RequestOperatingProfileModel } from "@/models/request.models/request.operating.profile.model";
import { plainToInstance } from "class-transformer";
import { VesselAdditionalInfoModel } from "@/models/models/vessel.additional.info.model";

export class OperatingProfileManager {
    private readonly api: AxiosInstance = httpclient;

    async getOperatingProfilesByVesselId(vesselId: number): Promise<ResponseOperatingProfileModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseOperatingProfileModel[]> = await this.api.get(`/OperatingProfile/GetOperatingProfilesByVesselId/${vesselId}`);
            response.data.forEach(p => {p.vessel.vesselAdditionalInfo = plainToInstance(VesselAdditionalInfoModel, p.vessel.vesselAdditionalInfo);});
            return plainToInstance(ResponseOperatingProfileModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async getOperatingProfilesByServiceIdQrCodeAsync(code: string): Promise<ResponseOperatingProfileModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseOperatingProfileModel[]> = await this.api.get(`/OperatingProfile/GetOperatingProfilesByServiceIdQrCode/${code}`);
            response.data.forEach(p => {p.vessel.vesselAdditionalInfo = plainToInstance(VesselAdditionalInfoModel, p.vessel.vesselAdditionalInfo);});
            return plainToInstance(ResponseOperatingProfileModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    async saveOperatingProfile(requestOperatingProfile: RequestOperatingProfileModel) {
        try {
            const response: AxiosResponse<RequestOperatingProfileModel> = await this.api.post(
                "/OperatingProfile/SaveOperatingProfile",
                requestOperatingProfile
            );

            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async deleteOperationProfile(id: number) {
        try {
            const response = await this.api.delete(`/OperatingProfile/DeleteOperatingProfile/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}
export const operatingProfileManager = new OperatingProfileManager();
