import { ComponentPublicInstance } from "vue";
import { IDropDownOptions } from "@/components/base.controls/colored.dropdown/colored.dropdown";

export const enumHelper = {
    getValueFromEnum(enumType: object, component: ComponentPublicInstance | null = null): IDropDownOptions[] {
        return Object.keys(enumType)
            .filter(item => {
                return isNaN(Number(item));
            })
            .map(item => {
                return {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    value: (enumType as any)[item],
                    text: component ? component.$t(`enums.${item}`) : item
                };
            });
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getEnumList(enumType: any): object[] {
        return Object.keys(enumType)
            .filter(item => {
                return isNaN(Number(item));
            })
            .map(key => enumType[key]);
    }
};
