import { Component, Prop } from "vue-facing-decorator";
import DialogBaseModuleControl from "../../dialog.base.control";
import { images } from "@/utilities/helpers/image.helper";
import { fileHelper } from "@/utilities/helpers/file.helper";

@Component({})
export default class AttachmentDownloadComponent extends DialogBaseModuleControl {
    @Prop()
    content!: string;

    @Prop()
    fileName!: string;

    @Prop()
    contentType!: string;

    get isImage() {
        return images.includes(this.contentType);
    }

    createUrl(): string {
        return `data:${this.contentType};base64,${this.content}`;
    }

    async downloadContent() {
        fileHelper.download(this.createUrl(), this.fileName);
    }
}
