import { ComponentPublicInstance } from "vue";

export const toastManager = {
    //possible types: success info warn error
    success(component: ComponentPublicInstance, summary: string, detail: string) {
        component.$toast.add({
            severity: "success",
            summary: summary,
            detail: detail,
            life: 3000
        });
    },
    error(component: ComponentPublicInstance, summary: string, detail: string) {
        component.$toast.add({
            severity: "error",
            summary: summary,
            detail: detail,
            life: 6000
        });
    },
    warn(component: ComponentPublicInstance, summary: string, detail: string) {
        component.$toast.add({
            severity: "warn",
            summary: summary,
            detail: detail,
            life: 6000
        });
    }
};
