import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2 text-uppercase d-flex" }
const _hoisted_2 = { class: "fw-bold flex-grow-1" }
const _hoisted_3 = { class: "mb-2 mt-4 text-uppercase d-flex" }
const _hoisted_4 = { class: "fw-bold flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      id: "columns-settings",
      visible: _ctx.showDialog,
      "onUpdate:visible": [
        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDialog) = $event)),
        _ctx.closed
      ],
      draggable: false,
      modal: true,
      class: "p-fluid fw-bold",
      header: _ctx.$t('table.editColumns.header')
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('global.button.restore'),
          "data-test-id": "data-columns-restore-button",
          icon: "pi pi-undo",
          class: "secondary-button mt-3",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.restore()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('global.button.cancel'),
          icon: "pi pi-times",
          class: "secondary-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closed()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('global.button.save'),
          "data-test-id": "data-columns-save-button",
          icon: "pi pi-check",
          class: "primary-button",
          onClick: _ctx.saveSettings
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t("table.editColumns.frozen")), 1)
        ]),
        _createVNode(_component_DataTable, {
          value: _ctx.frozenColumns,
          "reorderable-columns": true,
          onRowReorder: _ctx.onFrozenRowReorder
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "hideColumn",
              "header-style": "width: 3rem"
            }, {
              body: _withCtx(({ field, data }) => [
                _createVNode(_component_Checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                  binary: true,
                  "false-value": true,
                  "true-value": false
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { field: "header" }),
            _createVNode(_component_Column, { class: "text-end" }, {
              body: _withCtx(({ data }) => [
                _createVNode(_component_Button, {
                  label: _ctx.$t('global.button.unfreeze'),
                  icon: "pi pi-lock-open",
                  class: "secondary-button",
                  onClick: ($event: any) => (_ctx.freezeColumn(data, false))
                }, null, 8, ["label", "onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              "row-reorder": "",
              "header-style": "width: 3rem"
            })
          ]),
          _: 1
        }, 8, ["value", "onRowReorder"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.$t("table.editColumns.regular")), 1)
        ]),
        _createVNode(_component_DataTable, {
          value: _ctx.regularColumns,
          "reorderable-columns": true,
          onRowReorder: _ctx.onRegularRowReorder
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "hideColumn",
              "header-style": "width: 3rem"
            }, {
              body: _withCtx(({ field, data }) => [
                _createVNode(_component_Checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                  binary: true,
                  "false-value": true,
                  "true-value": false
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { field: "header" }),
            _createVNode(_component_Column, { class: "text-end" }, {
              body: _withCtx(({ data }) => [
                _createVNode(_component_Button, {
                  label: _ctx.$t('global.button.freeze'),
                  icon: "pi pi-lock",
                  class: "secondary-button",
                  onClick: ($event: any) => (_ctx.freezeColumn(data, true))
                }, null, 8, ["label", "onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              "row-reorder": "",
              "header-style": "width: 3rem"
            })
          ]),
          _: 1
        }, 8, ["value", "onRowReorder"])
      ]),
      _: 1
    }, 8, ["visible", "header", "onUpdate:visible"])
  ]))
}