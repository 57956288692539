import { AxiosInstance, AxiosResponse } from "axios";
import httpClient from "../utilities/helpers/http.client.axios";
import { OperatorModel } from "@/models/response.models/operator.model";
import { plainToInstance } from "class-transformer";
import { RequestCreateOperator } from "@/models/request.models/request.create.operator";
import { RequestUpdateOperator } from "@/models/request.models/request.update.operator";

export class OperatorManager {
    private readonly api: AxiosInstance = httpClient;

    public async getOperatorsAsync(): Promise<OperatorModel[] | Error> {
        try {
            const response: AxiosResponse<OperatorModel[]> = await this.api.get("/Operator/GetOperators");
            return plainToInstance(OperatorModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async getPreviousOperatorsAsync(id: number): Promise<OperatorModel[] | Error> {
        try {
            const response: AxiosResponse<OperatorModel[]> = await this.api.get(`/Operator/GetPreviousOperators/${id}`);
            return plainToInstance(OperatorModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async addOperatorAsync(request: RequestCreateOperator): Promise<OperatorModel | Error> {
        try {
            const response: AxiosResponse<OperatorModel> = await this.api.post(`/Operator/Add`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async updateOperatorAsync(request: RequestUpdateOperator): Promise<OperatorModel | Error> {
        try {
            const response: AxiosResponse<OperatorModel> = await this.api.post(`/Operator/Update`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async reasonsOperatorCannotBeSafelyDeletedAsync(id: number): Promise<string[] | Error> {
        try {
            const response: AxiosResponse<string[]> = await this.api.get(`/Operator/VesselsWithCurrentOperator/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async deleteOperatorAsync(id: number): Promise<boolean | Error> {
        try {
            const response: AxiosResponse<boolean> = await this.api.delete(`/Operator/Delete/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}

export const operatorManager = new OperatorManager();
