import { Type } from "class-transformer";
import { UserModel } from "../models/user.model";

export class ResponseSyncHistoryModel {
    @Type(() => Date)
    dateUser!: Date;

    createdByUser!: UserModel;

    @Type(() => Date)
    dateAdmin!: Date;
}
