import managers, { IApi } from "../managers/managers.plugin";
import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import { IAuthState } from "./auth.module";
import { IFleetState } from "./fleet.module";

export interface IState {
    auth: IAuthState;
    fleet: IFleetState;
}

export type IStore = Store<IState> & IApi;

export default createStore({
    plugins: [managers]
});

export const key: InjectionKey<IStore> = Symbol();

export const useStore = (): IStore => {
    return baseUseStore(key) as IStore;
};
