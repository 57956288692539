import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Login from "../components/pages/public/login/login.page.vue";
import authGuard from "./guards/auth.guard";
import devGuard from "./guards/dev.guard";
import { UserRoles } from "@/models/enums/user.roles";

const routes: Array<RouteRecordRaw> = [
    // NOTE: name define CSS style of divider
    {
        path: "/",
        name: "Home",
        component: () => import("../components/pages/admin/home/home.page.vue"),
        meta: {
            allowedRoles: [UserRoles.MjpAdmin, UserRoles.SuperAdmin, UserRoles.MjpUser],
            hideNavBar: false
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { hideNavBar: true }
    },
    {
        path: "/qr-add-attachments",
        name: "qrAddAttachments",
        component: () => import("../components/pages/public/add.attachments/qr.add.attachments.vue"),
        meta: { hideNavBar: true }
    },
    {
        path: "/fleet",
        name: "Fleet",
        component: () => import("../components/pages/admin/fleet/fleet.page.vue"),
        meta: { allowedRoles: [UserRoles.MjpAdmin, UserRoles.SuperAdmin, UserRoles.MjpUser] }
    },
    {
        path: "/vessel/:id",
        name: "Vessel",
        component: () => import("../components/pages/admin/vessel/vessel.page.vue"),
        meta: { allowedRoles: [UserRoles.MjpAdmin, UserRoles.SuperAdmin, UserRoles.MjpUser, UserRoles.SpUser] }
    },
    {
        path: "/service-agents",
        name: "serviceProviders",
        component: () => import("../components/pages/admin/service.providers/service.providers.vue"),
        meta: { allowedRoles: [UserRoles.MjpAdmin, UserRoles.SuperAdmin, UserRoles.MjpUser] }
    },
    {
        path: "/service-agent/:id",
        name: "Agent",
        component: () => import("../components/pages/admin/service.providers/agent/agent.vue"),
        meta: { allowedRoles: [UserRoles.MjpAdmin, UserRoles.SuperAdmin, UserRoles.MjpUser, UserRoles.SpUser] }
    },
    {
        path: "/tech-vessel/:id",
        name: "TechVessel",
        component: () => import("../components/pages/technician/vessel/vessel.page.vue"),
        meta: { allowedRoles: [UserRoles.MjpAdmin, UserRoles.SuperAdmin, UserRoles.MjpUser, UserRoles.SpUser] }
    },
    {
        path: "/serviceReports",
        name: "ServiceReports",
        component: () => import("../components/pages/admin/service.reports/service.reports.page.vue"),
        meta: { allowedRoles: [UserRoles.MjpAdmin, UserRoles.SuperAdmin, UserRoles.MjpUser] }
    },
    {
        path: "/admin",
        name: "Admin",
        component: () => import("../components/pages/admin/admin.settings/admin.settings.page.vue"),
        meta: { allowedRoles: [UserRoles.MjpAdmin, UserRoles.SuperAdmin] }
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../components/pages/admin/dashboard/dashboard.vue"),
        meta: { allowedRoles: [UserRoles.MjpAdmin, UserRoles.SuperAdmin, UserRoles.MjpUser, UserRoles.SpUser] }
    },
    {
        path: "/help",
        name: "Help",
        component: () => import("../components/pages/wiki/wiki.section.page.vue"),
        meta: { allowedRoles: [UserRoles.MjpAdmin, UserRoles.SuperAdmin, UserRoles.MjpUser, UserRoles.SpUser] }
    },
    {
        path: "/printpage/:id",
        name: "serviceReportPrint",
        component: () => import("../components/pages/admin/service.report.print/service.report.print.page.vue"),
        meta: { hideNavBar: true }
    },
    {
        path: "/underMaintenance",
        name: "underMaintenance",
        component: () => import("../components/pages/infrastructure.status/under.maintenance.page.vue"),
        meta: { hideNavBar: true }
    },
    {
        path: "/registration/:id",
        name: "registration",
        component: () => import("../components/pages/public/registration/registration.page.vue"),
        meta: { hideNavBar: true }
    },
    {
        path: "/passwordReset/:id",
        name: "passwordReset",
        component: () => import("../components/pages/public/password.reset/password.reset.vue"),
        meta: { hideNavBar: true }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() { return { top: 0, left: 0 };},
});

router.beforeEach(authGuard);

if (process.env.NODE_ENV === "development") {
    router.afterEach(devGuard);
}

export default router;
