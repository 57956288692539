import { Type } from "class-transformer";
import { UserModel } from "../models/user.model";

export class ResponseFleetVesselModel {
    id!: number;

    vesselNumber!: string;

    name!: string;

    shipyardCountry!: string;

    serviceProviderId: number | undefined;

    serviceProviderName!: string;

    @Type(() => Date)
    warrantyEndDate?: Date;

    get warrantyEndDateString(): string {
        return this.warrantyEndDate ? this.warrantyEndDate.toLocaleDateString() : "-";
    }

    hasWarrantyExpired!: boolean;

    warrantyStatusText?: string;

    @Type(() => Date)
    nextServiceDate!: Date;

    get nextServiceDateString(): string {
        return this.nextServiceDate ? this.nextServiceDate.toLocaleDateString() : "";
    }

    responsibleMjpUsers!: UserModel[];

    responsibleSpUsers!: UserModel[];

    vesselModelName!: string;

    waterJetType!: string;

    config!: string;

    shipyardName!: string;

    @Type(() => Date)
    commissionDate?: Date;

    get commissionDateString(): string {
        return this.commissionDate?.toString().serializedToLocalDateString() ?? "";
    }

    vesselStatus?: string;

    controlSystemType?: string;

    annualRunningHours!: number;

    countryName!: string;

    countryRegion!: string;

    ownershipType!: string;

    application!: string;

    hullType!: string;

    hullNumber!: string;

    shaft!: string;

    speed!: number;

    length!: number;

    engineMaker!: string;

    engineModel!: string;

    gearBoxMake!: string;

    gearBoxRatio!: string;

    hullMaterial!: string;

    intakeMaterial!: string;

    grid!: string;

    imoNo!: number;

    mmsi!: number;

    previousVesselName1!: string;

    previousVesselName2!: string;

    shipyardRegion!: string;

    buyerCode!: number;

    comment!: string;

    hasOffers: boolean = false;

    hasOffersText: string = "";

    [others: string]: unknown;
}
