import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import store, { IStore } from "./index";
import ResponseSoftwareVersionModel from "../models/response.models/response.software.version.model";
import { BaseModule } from "./base.module";

export interface SoftwareVersionState {
    softwareVersions: ResponseSoftwareVersionModel[];
}

@Module({
    store,
    name: "softwareVersion",
    dynamic: true,
    namespaced: true
})
class SoftwareVersionModule extends BaseModule implements SoftwareVersionState {
    private readonly store!: IStore;

    _softwareVersions: ResponseSoftwareVersionModel[] = [];

    get softwareVersions(): ResponseSoftwareVersionModel[] {
        return this._softwareVersions;
    }

    @Mutation
    async setIsSoftwareVersionLoading(value: boolean) {
        this.isLoading = value;
    }

    @Mutation
    public async setFetchSoftwareVersionsSuccessful(responseSoftwareVersions: ResponseSoftwareVersionModel[]) {
        this._softwareVersions = responseSoftwareVersions;
        this.isLoading = false;
    }

    @Mutation
    public async setFetchSoftwareVersionsUnsuccessful(error: Error) {
        this.isLoading = false;
        this.errors.push(error);
    }

    @Action
    async fetchSoftwareVersions(vesselId: number) {
        return this.fetchSoftwareVersionsByCode({ vesselId, code: null });
    }

    @Action
    async fetchSoftwareVersionsByCode(data: { vesselId: number; code: string | null }) {
        this.context.commit("setIsSoftwareVersionLoading", true);
        const response = await this.store.softwareVersionManager.getSoftwareVersionsByVesselId(data.vesselId, data.code);

        if (response instanceof Error) return this.context.commit("setFetchSoftwareVersionsUnsuccessful", response);

        return this.context.commit("setFetchSoftwareVersionsSuccessful", response);
    }
}

export const softwareVersionModule: SoftwareVersionModule = getModule(SoftwareVersionModule);
