import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { ResponseServiceModel } from "@/models/response.models/response.service.model";
import store, { IStore } from "./index";
import { BaseModule } from "./base.module";
import { ResponseTechnicianServiceModel } from "@/models/response.models/response.technician.service.model";
import Query from "@/models/models/query";

export interface ServiceState {
    services: ResponseServiceModel[];
    slimServices: ResponseServiceModel[];

    service: ResponseServiceModel;
    serviceErrors: Error[];
    isServiceLoading: boolean;

    servicesByQuery: ResponseTechnicianServiceModel[];
    serviceReportForms: ResponseTechnicianServiceModel[];

    technicianServiceErrors: Error[];
    isTechnicianServicesLoading: boolean;
}

@Module({
    store,
    name: "service",
    dynamic: true,
    namespaced: true
})
class ServiceModule extends BaseModule implements ServiceState {
    private readonly store!: IStore;

    _services: ResponseServiceModel[] = [];

    _service: ResponseServiceModel = new ResponseServiceModel();

    _serviceErrors: Error[] = [];

    isServiceLoading = false;

    get services(): ResponseServiceModel[] {
        return this._services;
    }

    get service(): ResponseServiceModel {
        return this._service;
    }

    get serviceErrors(): Error[] {
        return this._serviceErrors;
    }

    @Mutation
    async setIsServiceLoading(value: boolean) {
        this.isServiceLoading = value;
    }

    @Mutation
    public setFetchServiceSuccessful(responseService: ResponseServiceModel[]) {
        this._services = responseService;
        this.isServiceLoading = false;
    }

    @Mutation
    public setFetchServiceUnsuccessful(error: Error) {
        this._serviceErrors.push(error);
        this.isServiceLoading = false;
    }

    @Mutation
    async setIsServiceCompletedLoading(value: boolean) {
        this.isServiceLoading = value;
    }

    @Mutation
    public setFetchServiceCompletedSuccessful(responseServiceModel: ResponseServiceModel) {
        this._service = responseServiceModel;
        this.isServiceLoading = false;
    }

    @Mutation
    public async setFetchServiceCompletedUnsuccessful(error: Error) {
        this._serviceErrors.push(error);
        this.isServiceLoading = false;
    }

    @Mutation
    public async resetState() {
        this._services = [];
        this._service = new ResponseServiceModel();
        this._serviceErrors = [];
        this.isServiceLoading = false;
        this.isTechnicianServicesLoading = false;
    }

    @Action
    public async reset() {
        return this.context.commit("resetState");
    }

    @Action
    public async fetchServices(id: number) {
        this.context.commit("setIsServiceLoading", true);
        const response = await this.store.serviceManager.getServicesForVessel(id);

        if (response instanceof Error) {
            return this.context.commit("setFetchServiceUnsuccessful", response);
        }

        return this.context.commit("setFetchServiceSuccessful", response);
    }

    @Action
    async fetchServicesForTechnician(vesselId: number) {
        this.context.commit("setIsServiceLoading", true);
        const response = await this.store.serviceManager.getServicesForTechnician(vesselId);

        if (response instanceof Error) return this.context.commit("setFetchServiceUnsuccessful", response);

        return this.context.commit("setFetchServiceSuccessful", response);
    }

    @Action
    async fetchServiceFullData(id: number, includePersonnel: boolean = true) {
        this.context.commit("setIsServiceCompletedLoading", true);
        const response = await this.store.serviceManager.getServiceFullDataById(id, includePersonnel);

        if (response instanceof Error) return this.context.commit("setFetchServiceCompletedUnsuccessful", response);

        return this.context.commit("setFetchServiceCompletedSuccessful", response);
    }

    @Action
    async fetchServiceFullDataByCodeAsync(code: string) {
        this.context.commit("setIsServiceCompletedLoading", true);
        const response = await this.store.serviceManager.getServiceByQrCodeAsync(code);

        if (response instanceof Error) return this.context.commit("setFetchServiceCompletedUnsuccessful", response);

        return this.context.commit("setFetchServiceCompletedSuccessful", response);
    }

    //Slim Services

    private _slimServices: ResponseServiceModel[] = [];

    get slimServices(): ResponseServiceModel[] {
        return this._slimServices;
    }

    @Mutation
    async setSlimServices(responseServices: ResponseServiceModel[]) {
        this._slimServices = responseServices;
        this.isServiceLoading = false;
    }

    @Action
    async fetchSlimServicesAsync(vesselId: number) {
        const response = await this.store.serviceManager.getSlimServicesForVesselAsync(vesselId);

        if (response instanceof Error) return;

        return this.context.commit("setSlimServices", response);
    }

    //Fleet technician

    private _servicesByQuery: ResponseTechnicianServiceModel[] = [];
    private _serviceReportForms: ResponseTechnicianServiceModel[] = [];

    private _technicianServiceErrors: Error[] = [];

    public isTechnicianServicesLoading = false;

    get servicesByQuery(): ResponseTechnicianServiceModel[] {
        return this._servicesByQuery;
    }

    get serviceReportForms(): ResponseTechnicianServiceModel[] {
        return this._serviceReportForms;
    }

    get technicianServiceErrors(): Error[] {
        return this._technicianServiceErrors;
    }

    @Mutation
    public async setFetchServicesByQuerySuccessful(responseServices: ResponseTechnicianServiceModel[]) {
        this._servicesByQuery = responseServices;
        this.isTechnicianServicesLoading = false;
    }

    @Mutation
    public async setFetchServicesByQueryUnsuccessful(error: Error) {
        this._technicianServiceErrors.push(error);
        this.isTechnicianServicesLoading = false;
    }

    @Mutation
    public async setFetchServiceReportFormsSuccessful(responseServices: ResponseTechnicianServiceModel[]) {
        this._serviceReportForms = responseServices;
        this.isTechnicianServicesLoading = false;
    }

    @Mutation
    public async setFetchServiceReportFormsUnsuccessful(error: Error) {
        this._technicianServiceErrors.push(error);
        this.isTechnicianServicesLoading = false;
    }

    @Mutation
    public async setIsTechnicianServicesLoading(value: boolean) {
        this.isTechnicianServicesLoading = value;
    }

    @Action
    async fetchServicesByQuery(query: Query) {
        this.context.commit("setIsTechnicianServicesLoading", true);

        const response = await this.store.serviceManager.getServicesAsync(query);

        if (response instanceof Error) return this.context.commit("setFetchServicesByQueryUnsuccessful", response);

        return this.context.commit("setFetchServicesByQuerySuccessful", response);
    }

    @Action
    async fetchServiceReportFormsByQuery(query: Query) {
        this.context.commit("setIsTechnicianServicesLoading", true);

        const response = await this.store.serviceManager.getServiceReportFormsAsync(query);

        if (response instanceof Error) return this.context.commit("setFetchServiceReportFormsUnsuccessful", response);

        return this.context.commit("setFetchServiceReportFormsSuccessful", response);
    }
}

export const serviceModule: ServiceModule = getModule(ServiceModule);
