import { VesselFullModel } from "@/models/models/vessel.full.model";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { VesselNameModel } from "@/models/models/vessel.name.model";
import { BaseModule } from "./base.module";
import store, { IStore } from "./index";
import { AliasModel } from "@/models/models/alias.model";
import ResponseVesselMultiMediaModel from "@/models/response.models/response.vessel.multi.media.model";

export interface IVesselState {
    currentVessel: VesselFullModel;

    aliases: AliasModel[];
    isAliasesLoading: boolean;
    aliasesErrors: Error[];

    multiMedia: ResponseVesselMultiMediaModel[];
    marqueeImage: ResponseVesselMultiMediaModel;

    redirectedFrom?: string;
}

@Module({
    store,
    name: "vessel",
    dynamic: true,
    namespaced: true
})
class VesselModule extends BaseModule implements IVesselState {
    private readonly store!: IStore;

    private _currentVessel!: VesselFullModel;

    public get currentVessel(): VesselFullModel {
        return this._currentVessel;
    }

    @Mutation
    public async setIsVesselLoading(value: boolean) {
        this.isLoading = value;
    }

    @Mutation
    public async setFetchVesselSuccessful(responseVesselInfo: VesselFullModel) {
        this._currentVessel = responseVesselInfo;
        this.isLoading = false;
    }

    @Mutation
    public async setFetchVesselUnsuccessful(error: Error) {
        this.errors.push(error);
        this.isLoading = false;
    }

    @Action
    public async fetchVesselInfoById(id: number) {
        this.context.commit("setIsVesselLoading", true);
        const response = await this.store.vesselManager.getVesselFullInfo(id);

        return await this.setVesselFullInfoContextAsync(response);
    }

    @Action
    public async fetchVesselInfoByServiceIdCodeAsync(code: string) {
        this.context.commit("setIsVesselLoading", true);
        const response = await this.store.vesselManager.getVesselFullInfoByServiceIdQrCode(code);

        return await this.setVesselFullInfoContextAsync(response);
    }

    @Action
    private async setVesselFullInfoContextAsync(response: VesselFullModel | Error): Promise<void> {
        if (response instanceof Error) return this.context.commit("setFetchVesselUnsuccessful", response);

        if (!response.vesselAdditionalInfo.vesselNames) response.vesselAdditionalInfo.vesselNames = [];

        const maxVersion = response.vesselAdditionalInfo.vesselNames?.length ? Math.max(...response.vesselAdditionalInfo.vesselNames.map(n => n.version)) : 3;

        const newNames = [];
        for (let index = 0; index < 3; index++) {
            const existName = response.vesselAdditionalInfo.vesselNames.find(vn => vn.version == maxVersion - index);
            if (!existName) newNames.push({ name: "", version: maxVersion - index } as VesselNameModel);
            else newNames.push(existName);
        }

        response.vesselAdditionalInfo.vesselNames = newNames;

        return this.context.commit("setFetchVesselSuccessful", response);
    }

    //Aliases
    isAliasesLoading = false;

    private _aliases: AliasModel[] = [];

    private _aliasesErrors: Error[] = [];

    public get aliases(): AliasModel[] {
        return this._aliases;
    }

    public get aliasesErrors(): Error[] {
        return this._aliasesErrors;
    }

    @Mutation
    public setFetchAliasesSuccessful(response: AliasModel[]): void {
        this._aliases = response;
        this.isAliasesLoading = true;
    }

    @Mutation
    public setFetchAliasesUnsuccessful(error: Error): void {
        this._aliasesErrors.push(error);
        this.isAliasesLoading = false;
    }

    @Mutation
    public setAliasesLoading(value: boolean): void {
        this.isAliasesLoading = value;
    }

    @Action
    public async fetchAliases(id: number):  Promise<void>  {
        this.context.commit("setAliasesLoading", true);

        const response = await this.store.vesselManager.getAliases(id);

        if (response instanceof Error) return this.context.commit("setFetchAliasesUnsuccessful", response);

        return this.context.commit("setFetchAliasesSuccessful", response);
    }

    //MultiMedia
    private _multiMedia: ResponseVesselMultiMediaModel[] = [];

    public get multiMedia(): ResponseVesselMultiMediaModel[] {
        return this._multiMedia;
    }

    public get marqueeImage(): ResponseVesselMultiMediaModel {
        const res = this.multiMedia.find(m => m.isMarqueeImage);
        return res ?? new ResponseVesselMultiMediaModel();
    }

    @Mutation
    public setFetchMultiMediaSuccessful(response: ResponseVesselMultiMediaModel[]): void {
        this._multiMedia = response;
        this.isLoading = false;
    }

    @Mutation
    public setFetchMultiMediaUnsuccessful(error: Error): void {
        this.errors.push(error);
        this.isLoading = false;
    }

    @Action
    public async fetchMultiMediaAsync(vesselId: number): Promise<void> {
        this.context.commit("setIsVesselLoading", true);

        const response = await this.store.vesselManager.getMultiMediaAsync(vesselId);

        if(response instanceof Error) return this.context.commit("setFetchMultiMediaUnsuccessful", response);

        return this.context.commit("setFetchMultiMediaSuccessful", response);
    }

    //Redirection
     redirectedFrom?: string;
}

export const vesselModule: VesselModule = getModule(VesselModule);
