import { AxiosResponse } from "axios";
import { fileHelper } from "./file.helper";

export const excelFileHelper = {
    download(response: AxiosResponse<any, any>, fileName = "Download.xlsx") {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        try {
            fileName = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
        } finally {
            fileHelper.download(url, fileName);
        }
    }
};
