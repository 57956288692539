import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { ResponseEventLogModel } from "@/models/response.models/response.eventLog.model";
import { BaseModule } from "./base.module";
import store, { IStore } from "./index";
import EventLogTypeModel from "@/models/models/eventLog/event.log.type.model";
import Query from "@/models/models/query";

export interface IEventLogState {
    eventLogs: ResponseEventLogModel[];
    eventLogsByQuery: ResponseEventLogModel[];
    types: EventLogTypeModel[];
}

@Module({
    store,
    name: "eventlog",
    dynamic: true,
    namespaced: true
})
class EventlogModule extends BaseModule implements IEventLogState {
    private readonly store!: IStore;

    private _eventLogs: ResponseEventLogModel[] = [];
    private _eventLogsByQuery: ResponseEventLogModel[] = [];
    private _types: EventLogTypeModel[] = [];

    public get eventLogs(): ResponseEventLogModel[] {
        return this._eventLogs;
    }
 
    public get types(): EventLogTypeModel[] {
        return this._types;
    }

    public get eventLogsByQuery(): ResponseEventLogModel[] {
        return this._eventLogsByQuery;
    }

    @Mutation
    public async setIsEventLogLoading(value: boolean) {
        this.isLoading = value;
    }

    @Mutation
    public async setFetchEventLogSuccessful(responseEventLog: ResponseEventLogModel[]) {
        this._eventLogs = responseEventLog;
        this.isLoading = false;
    }

    @Mutation
    public async setFetchTypesSuccessful(res: EventLogTypeModel[]) {
        this._types = res;
        this.isLoading = false;
    }

    @Mutation
    public async setFetchTypesUnsuccessful(error: Error) {
        this.errors.push(error);
        this.isLoading = false;
    }


    @Action
    public async fetchEventLogs(vesselId: number) {
        this.context.commit("setIsEventLogLoading", true);
        const response = await this.store.eventLogManager.getEventLogs(vesselId);

        if (response instanceof Error) return this.context.commit("setFetchEventLogUnsuccessful", response);

        return this.context.commit("setFetchEventLogSuccessful", response);
    }

    @Action
    public async fetchTypesAsync() {
        this.context.commit("setIsEventLogLoading", true);
        const response = await this.store.eventLogManager.getEventLogTypesAsync();

        if (response instanceof Error) return this.context.commit("setFetchTypesUnsuccessful", response);

        return this.context.commit("setFetchTypesSuccessful", response);
    }

    @Mutation
    public async setFetchEventLogsByQuerySuccessful(responseEventLog: ResponseEventLogModel[]) {
        this._eventLogsByQuery = responseEventLog;
        this.isLoading = false;
    }

    @Mutation
    public async setFetchEventLogsByQueryUnsuccessful(error: Error) {
        this.errors.push(error);
        this.isLoading = false;
    }

    @Action
    public async fetchEventLogsByQuery(query: Query) {
        this.context.commit("setIsEventLogLoading", true);
        const response = await this.store.eventLogManager.getEventLogsByQueryAsync(query);

        if (response instanceof Error) return this.context.commit("setFetchEventLogsByQueryUnsuccessful", response);

        return this.context.commit("setFetchEventLogsByQuerySuccessful", response);
    }
}

export const eventLogModule: EventlogModule = getModule(EventlogModule);
