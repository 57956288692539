<template>
    <div class="global-navbar-container">
        <div class="global-navbar">
            <div class="n-start" @click="toHomePageAsync">
                <!-- <img alt="" style="width: 7rem; margin-right: 1rem" :src="require('../../../../public/icons/logo_MJP.svg')" /> -->
                <img alt="" class="m-logo" :src="require('../../../../public/mjp_m_white.svg')" />

                <img v-if="!isTabletView" alt="" style="width: 7rem" :src="require('../../../../public/fleethub-logo.svg')" />
                <span class="text-nowrap logo-label logo-hide printing">{{ $t("global.serviceReport") }}</span>
            </div>

            <div class="n-links">
                <router-link v-for="item of menuBarItems" v-slot="{ href, isActive }" :to="item.to" custom>
                    <div :class="['item-container', { 'active-link': isActive }]">
                        <img alt="" :src="item.svg" :class="isActive ? 'img-active' : 'img-inactive'" style="margin-right: 0.2rem" />
                        <a :href="href" class="menu-item-link not-printing" :style="{ width: isTabletView ? '2rem' : 'auto' }"> {{ item.label }} </a>
                    </div>
                </router-link>
            </div>

            <div class="n-end" id="n-end">
                <img
                    v-if="!isMobileView && !isTabletView"
                    :class="['dashboard', isDashboardActive ? 'active' : '']"
                    alt=""
                    :src="require('../../../../public/icons/dashboard.svg')"
                    @click="goToDashboardAsync" />
                <img
                    :style="{ width: !isTabletView ? '1.8rem' : '1.5rem', marginLeft: '0.5rem' }"
                    :class="['dashboard', isWikiSectionActive ? 'active' : '']"
                    alt=""
                    :src="require('../../../../public/help-icon.svg')"
                    @click="goToWikiSectionAsync" />

                <div class="n-user-menu" id="n-user-menu" @click="toggleUserMenu">
                    <img alt="" src="../../../../public/no.avatar.svg" />
                    <div class="hamburger">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>
                </div>

                <div v-if="showMenu" class="n-user-menu-content" id="n-user-menu-content">
                    <div v-for="item of userMenuItems" :class="['n-user-item', { clickable: item.clickable }]" @click="item.command">
                        <i v-if="item.icon" :class="item.icon"></i>
                        <span>{{ item.label }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./navigation.bar.control.ts"></script>
<style lang="scss" scoped src="./navigation.bar.control.scoped.scss"></style>
