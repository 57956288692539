import { AxiosInstance, AxiosResponse } from "axios";
import httpclient from "../utilities/helpers/http.client.axios";
import { FeedbackModel } from "../models/models/feedback.model";

export class FeedbackManager {
    private readonly api: AxiosInstance = httpclient;

    async saveFeedback(FeedbackModel: FeedbackModel) {
        try {
            const response: AxiosResponse<FeedbackModel> = await this.api.post("/Feedback/SaveFeedback", FeedbackModel);

            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}
export const feedbackManager = new FeedbackManager();
