type EventBusType = { on: (event: string, callback: (payload: any) => void) => void; emit: (event: string, payload: any) => void };

const eventBus: EventBusType = (() => {
    const events: Record<string, (payload: any) => void> = {};

    return {
        on(event, callback) { events[event] = callback; },
        emit(event, payload) { if (events[event]) events[event](payload); }
    };
})();

export default eventBus;


export const prevSectionEvent: string = "prevSection";
export const nextSectionEvent: string = "nextSection";
export const activeSubMenuDotIndexEvent: string = "activeSubMenuDotIndex";
export const subMenuItemsEvent: string = "subMenuItems";

export const expandServiceAccordionEvent: string = "expandServiceAccordion";

export const resetEvent: string = "reset";

