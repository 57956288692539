import { AxiosInstance, AxiosResponse } from "axios";
import httpclient from "../utilities/helpers/http.client.axios";
import { ContactModel } from "@/models/models/contact.model";
import { ResponseSyncHistoryModel } from "@/models/response.models/response.sync.history.model";
import { plainToInstance } from "class-transformer";
import { ResponseIsServiceProviderRemovable } from "@/models/response.models/response.is.service.provider.removable";
import { RequestIsServiceProviderRemovable } from "@/models/request.models/request.is.service.provider.removable";
import AgentModel from "@/models/models/agent.model";
import { UserModel } from "@/models/models/user.model";

export class ContactManager {
    private readonly api: AxiosInstance = httpclient;

    public async getAllServiceProviders(): Promise<ContactModel[] | Error> {
        try {
            const response: AxiosResponse<ContactModel[]> = await this.api.get(`/Contact/GetAllServiceProviders`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getServiceAgentsAsync(): Promise<AgentModel[] | Error> {
        try {
            const response: AxiosResponse<AgentModel[]> = await this.api.get(`/Contact/ServiceAgents`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }


    public async getAgentAsync(id: number): Promise<AgentModel | Error> {
        try {
            const response: AxiosResponse<AgentModel> = await this.api.get(`/Contact/Agent/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    
    public async getServiceProviderUsersAsync(id: number): Promise<UserModel[] | Error> {
        try {
            const response: AxiosResponse<UserModel[]> = await this.api.get(`/Contact/ServiceProviderUsers/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getContacts(): Promise<ContactModel[] | Error> {
        try {
            const response: AxiosResponse<ContactModel[]> = await this.api.get(`/Contact/GetContacts`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async synchronizeContacts(): Promise<void | Error> {
        try {
            await this.api.post("/Contact/SynchronizeContacts");
        } catch (error) {
            return error as Error;
        }
    }

    public async getSyncHistory(): Promise<ResponseSyncHistoryModel | Error> {
        try {
            const response: AxiosResponse<ResponseSyncHistoryModel> = await this.api.get("/Contact/GetSyncHistory");
            return plainToInstance(ResponseSyncHistoryModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async areServiceProvidersRemovableAsync(request: RequestIsServiceProviderRemovable): Promise<ResponseIsServiceProviderRemovable[] | Error> {
        try {
            const response: AxiosResponse<ResponseIsServiceProviderRemovable[]> = await this.api.post("/Contact/AreServiceProvidersRemovable", request);
            return plainToInstance(ResponseIsServiceProviderRemovable, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async updateAgentsAsync(request: AgentModel): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.put("/Contact/UpdateAgents", request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async addLogoAsync(request: FormData): Promise<any | Error> {
        try {
            const response = await this.api.post("/Contact/AddLogo", request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getLogoAsync(id: number): Promise<any | Error> {
        try {
            const response = await this.api.get(`/Contact/Logo/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}
export const contactManager = new ContactManager();
