import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20ec8d6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "site-container" }
const _hoisted_2 = { class: "router-container" }
const _hoisted_3 = {
  key: 0,
  class: "help-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ToastComponent = _resolveComponent("ToastComponent")!
  const _component_Button = _resolveComponent("Button")!
  const _component_NavbarComponent = _resolveComponent("NavbarComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.$route.meta.hideNavBar && !_ctx.isMobileView)
      ? (_openBlock(), _createBlock(_component_NavigationBar, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$route.meta.hideNavBar), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view),
      _createVNode(_component_ToastComponent),
      (!_ctx.hideFeedbackForm)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (!_ctx.isMobileView)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  label: _ctx.$t('global.leaveFeedback'),
                  icon: "pi pi-envelope",
                  class: "add-feedback-button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFeedbackForm()))
                }, null, 8, ["label"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps(_ctx.componentData, { onOnSaved: _ctx.showMessage }), null, 16, ["onOnSaved"]))
    ]),
    (_ctx.isMobileView && !_ctx.$route.meta.hideNavBar && !_ctx.isTabletView)
      ? (_openBlock(), _createBlock(_component_NavbarComponent, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}