<template>
    <div class="container-fluid" style="min-height: 100vh;">
        <div class="row">
            <div class="col-12 col-sm-6 col-lg-5">
                <div class="row">
                    <div class="col-10 offset-1 offset-lg-2 col-lg-8 my-5 d-inline-flex">
                        <img alt="" :class="[isMobileView ? 'logo' : '']" :src="require('../../../../../public/Logo.svg')" style="margin-right: 1.5rem;" />
                        <img alt="" :class="[isMobileView ? 'logo' : '']" :src="require('../../../../../public/fleethub-logo.svg')" />
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-10 offset-1 offset-lg-2 col-lg-8">
                        <LoginFormControl @submit="onSubmitLogin"></LoginFormControl>
                    </div>
                </div>
            </div>
            <div class="only-desktop-visible col-sm-6 d-md-flex col-lg-7 justify-content-center px-0 vh-100">
                <img alt=""
                    class="login-side-image"
                    :src="require('../../../../../public/login.side.image.jpg')" />
            </div>
        </div>
    </div>
</template>

<script src="./login.page.ts"></script>
<style lang="scss" scoped src="./login.page.scoped.scss"></style>
