import { AxiosInstance, AxiosResponse } from "axios";
import httpclient from "../utilities/helpers/http.client.axios";
import { ServiceCommentModel } from "@/models/response.models/service.comment.model";
import { ServiceCommentMultiMediaModel } from "@/models/models/service.comment.multi.media.model";

export class ServiceCommentManager {
    private readonly api: AxiosInstance = httpclient;

    async saveServiceComment(requestServiceComment: ServiceCommentModel): Promise<ServiceCommentModel | Error> {
        try {
            const response: AxiosResponse<ServiceCommentModel> = await this.api.post("/ServiceComment/SaveServiceComment", requestServiceComment);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async deleteServiceComment(id: number) {
        try {
            const response = await this.api.delete(`/ServiceComment/DeleteServiceComment/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async createMultiMediaAsync(requestAttachment: FormData): Promise<any | Error> {
        try {
            const response = await this.api.post("/ServiceComment/CreateMultiMedia", requestAttachment);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getMultiMediaAsync(serviceId: number): Promise<ServiceCommentMultiMediaModel[] | Error> {
        try {
            const response: AxiosResponse<ServiceCommentMultiMediaModel[]> = await this.api.get(`/ServiceComment/GetMultiMedia/${serviceId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getMultiMediaByServiceIdQrCodeAsync(code: string): Promise<ServiceCommentMultiMediaModel[] | Error> {
        try {
            const response: AxiosResponse<ServiceCommentMultiMediaModel[]> = await this.api.get(`/ServiceComment/GetMultiMediaByServiceIdQrCode/${code}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getQrCodeParamsAsync(serviceId: number, role: number): Promise<string | Error> {
        try {
            const response = await this.api.get(`/ServiceComment/GetQrCodeParams/${serviceId}/${role}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getMultiMediaContentAsync(attachmentId: number, code: string | null = null): Promise<string | Error> {
        try {
            const response = await this.api.get(`/ServiceComment/GetMultiMediaContent/${attachmentId}?code=${code}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async deleteMultiMediaAsync(id: number): Promise<boolean | Error> {
        try {
            const response = await this.api.delete(`/ServiceComment/DeleteMultiMedia/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}
export const serviceCommentManager = new ServiceCommentManager();
