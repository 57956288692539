import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { UserRoles } from "@/models/enums/user.roles";
import { authModule } from "@/store/auth.module";

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const { allowedRoles }: { allowedRoles: UserRoles[] } = to.meta as { allowedRoles: UserRoles[] };
    if (allowedRoles?.length && !authModule.isAuthorized) {
        return next({ path: "/login", query: { returnUrl: to.path } });
    }

    const currentRole = authModule.currentUser?.role as UserRoles;
    if (allowedRoles?.length && authModule.isAuthorized && !allowedRoles.find(r => (r & currentRole) == r)) {
        return next({ path: "/" });
    }
    next();
};
