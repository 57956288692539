export enum UserRoles {
    None = 0,
    SuperAdmin = 1,
    MjpAdmin = 2,
    MjpUser = 4,
    SpUser = 8
    // NextRole = 16
    // Admins = SuperAdmin | MjpAdmin,
    // Users = MjpUser | SpUser,
    // AdminsAndUsers = Admins | Users,
    // All = 65535 // hardcoded 16 roles
}
