<template>
    <div>
        <Button class="text-nowrap action-menu" aria-controls="action-menu" @click="onClickActionMenuToggle">{{ buttonText }} <i class="pi pi-angle-down px-1"></i> </Button>
        <Menu v-if="!isMultiSelect" id="action-menu" ref="actionMenu" :model="actionMenuItems" :popup="true"> </Menu>

        <Menu v-else id="action-menu" ref="actionMenu" :model="actionMenuItems" :popup="true">

            <template #item="{ item, props }">
                <div style="display: flex; padding: 0.3rem 1rem;">
                    <Checkbox :binary="true" v-model="item.isToggled"  @click="item.command" />
                    <span style="margin-left: 0.5rem;">{{ item.label }}</span>
                </div>
            </template>

         </Menu>
    </div>
</template>
<script src="./action.menu.ts"></script>
<style lang="scss" scoped src="./action.menu.scoped.scss"></style>
