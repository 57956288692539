import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import store, { IStore } from "./index";
import ResponseOperatingProfileModel from "../models/response.models/response.operating.profile.model";
import { BaseModule } from "./base.module";

export interface OperatingProfileState {
    operatingProfiles: ResponseOperatingProfileModel[];
}

@Module({
    store,
    name: "operatingProfile",
    dynamic: true,
    namespaced: true
})
class OperatingProfileModule extends BaseModule implements OperatingProfileState {
    private readonly store!: IStore;

    _operatingProfiles: ResponseOperatingProfileModel[] = [];

    get operatingProfiles(): ResponseOperatingProfileModel[] {
        return this._operatingProfiles;
    }

    @Mutation
    async setIsOperatingProfileLoading(value: boolean) {
        this.isLoading = value;
    }

    @Mutation
    public async setFetchOperatingProfilesSuccessful(responseOperatingProfiles: ResponseOperatingProfileModel[]) {
        this._operatingProfiles = responseOperatingProfiles;
        this.isLoading = false;
    }

    @Mutation
    public async setFetchOperatingProfilesUnsuccessful(error: Error) {
        this.isLoading = false;
        this.errors.push(error);
    }

    @Action
    async fetchOperatingProfiles(vesselId: number) {
        this.context.commit("setIsOperatingProfileLoading", true);
        const response = await this.store.operatingProfileManager.getOperatingProfilesByVesselId(vesselId);

        if (response instanceof Error) return this.context.commit("setFetchOperatingProfilesUnsuccessful", response);

        return this.context.commit("setFetchOperatingProfilesSuccessful", response);
    }

    @Action
    async fetchOperatingProfilesByServiceIdCodeAsync(code: string) {
        this.context.commit("setIsOperatingProfileLoading", true);
        const response = await this.store.operatingProfileManager.getOperatingProfilesByServiceIdQrCodeAsync(code);

        if (response instanceof Error) return this.context.commit("setFetchOperatingProfilesUnsuccessful", response);

        return this.context.commit("setFetchOperatingProfilesSuccessful", response);
    }
}

export const operatingProfileModule: OperatingProfileModule = getModule(OperatingProfileModule);
