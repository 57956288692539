import { AxiosInstance, AxiosResponse } from "axios";
import httpClient from "../utilities/helpers/http.client.axios";

export class InfrastructureManager {
    private readonly api: AxiosInstance = httpClient;

    public async isApiAliveAsync(): Promise<boolean> {
        try {
            const response: AxiosResponse<boolean> = await this.api.get("/InfrastructureStatus/IsAlive");
            return response.data;
        } catch (error) {
            return false;
        }
    }

    public async cleanseCursoryDataAsync(): Promise<boolean> {
        try {
            const response: AxiosResponse<boolean> = await this.api.get("/InfrastructureStatus/CleanseCursoryData");
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export const infrastructureStatusManager = new InfrastructureManager();
